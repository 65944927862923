@charset "UTF-8";
/* Colors */
/* Typography */
/* Font Weights */
@font-face {
  font-display: swap;
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/notosans-bold.woff2") format("woff2"), url("../fonts/notosans-bold.woff") format("woff");
}
@font-face {
  font-display: swap;
  font-family: "Noto Sans";
  font-style: italic;
  font-weight: 700;
  src: url("../fonts/notosans-bolditalic.woff2") format("woff2"), url("../fonts/notosans-bolditalic.woff") format("woff");
}
@font-face {
  font-display: swap;
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/notosans-semibold.woff2") format("woff2"), url("../fonts/notosans-semibold.woff") format("woff");
}
@font-face {
  font-display: swap;
  font-family: "Noto Sans";
  font-style: italic;
  font-weight: 600;
  src: url("../fonts/notosans-semibolditalic.woff2") format("woff2"), url("../fonts/notosans-semibolditalic.woff") format("woff");
}
@font-face {
  font-display: swap;
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/notosans-regular.woff2") format("woff2"), url("../fonts/notosans-regular.woff") format("woff");
}
@font-face {
  font-display: swap;
  font-family: "Noto Sans";
  font-style: italic;
  font-weight: 400;
  src: url("../fonts/notosans-italic.woff2") format("woff2"), url("../fonts/notosans-italic.woff") format("woff");
}
@font-face {
  font-display: swap;
  font-family: "Noto Sans Display";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/notosansdisplay-bold.woff2") format("woff2"), url("../fonts/notosansdisplay-bold.woff") format("woff");
}
@font-face {
  font-display: swap;
  font-family: "Noto Sans Display";
  font-style: italic;
  font-weight: 700;
  src: url("../fonts/notosansdisplay-bolditalic.woff2") format("woff2"), url("../fonts/notosansdisplay-bolditalic.woff") format("woff");
}
@font-face {
  font-display: swap;
  font-family: "Noto Sans Display";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/notosansdisplay-semibold.woff2") format("woff2"), url("../fonts/notosansdisplay-semibold.woff") format("woff");
}
@font-face {
  font-display: swap;
  font-family: "Noto Sans Display";
  font-style: italic;
  font-weight: 600;
  src: url("../fonts/notosansdisplay-semibolditalic.woff2") format("woff2"), url("../fonts/notosansdisplay-semibolditalic.woff") format("woff");
}
@font-face {
  font-display: swap;
  font-family: "Noto Sans Display";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/notosansdisplay-regular.woff2") format("woff2"), url("../fonts/notosansdisplay-regular.woff") format("woff");
}
@font-face {
  font-display: swap;
  font-family: "Noto Sans Display";
  font-style: italic;
  font-weight: 400;
  src: url("../fonts/notosansdisplay-italic.woff2") format("woff2"), url("../fonts/notosansdisplay-italic.woff") format("woff");
}
@font-face {
  font-display: swap;
  font-family: "Noto Serif";
  font-style: normal;
  font-weight: 900;
  src: url("../fonts/notoserif-black.woff2") format("woff2"), url("../fonts/notoserif-black.woff") format("woff");
}
@font-face {
  font-display: swap;
  font-family: "Noto Serif";
  font-style: italic;
  font-weight: 900;
  src: url("../fonts/notoserif-blackitalic.woff2") format("woff2"), url("../fonts/notoserif-blackitalic.woff") format("woff");
}
@font-face {
  font-display: swap;
  font-family: "Noto Serif";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/notoserif-bold.woff2") format("woff2"), url("../fonts/notoserif-bold.woff") format("woff");
}
@font-face {
  font-display: swap;
  font-family: "Noto Serif";
  font-style: italic;
  font-weight: 700;
  src: url("../fonts/notoserif-bolditalic.woff2") format("woff2"), url("../fonts/notoserif-bolditalic.woff") format("woff");
}
@font-face {
  font-display: swap;
  font-family: "Noto Serif";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/notoserif-regular.woff2") format("woff2"), url("../fonts/notoserif-regular.woff") format("woff");
}
@font-face {
  font-display: swap;
  font-family: "Noto Serif";
  font-style: italic;
  font-weight: 400;
  src: url("../fonts/notoserif-italic.woff2") format("woff2"), url("../fonts/notoserif-italic.woff") format("woff");
}
.full-bleed-blue-500 {
  margin-left: 50%;
  transform: translateX(-50%);
  width: 100vw;
}

.tabs a.tab, .menu__item, .tag__link, .link--inverse, ol a:not([class]),
blockquote a:not([class]),
p a:not([class]),
ul:not(.link-list) a:not([class]), a:not([class]),
.link--breadcrumb,
.link, .button, .cta, .accordion__control {
  --color: var(--off-white);
  --background-color: var(--blue-500);
  --border-color: transparent;
  --border-width: 0.0625rem;
  --active-background-color: var(--white);
  --active-box-shadow: var(--focus-box-shadow);
  --active-color: var(--blue-500);
  --disabled-background-color: var(--blue-150);
  --disabled-border-color: var(--blue-150);
  --disabled-color: var(--off-white);
  --focus-background-color: var(--blue-500);
  --focus-border-color: var(--blue-500);
  --focus-box-shadow:
  	0 0 0 calc(2 * var(--border-width)) var(--focus-border-color) inset,
  	0 0 0 calc(1 * var(--border-width)) var(--parent-background-color),
  	0 0 0 calc(3 * var(--border-width)) var(--focus-border-color);
  --focus-color: var(--off-white);
  --hover-background-color: var(--blue-600);
  --hover-border-color: var(--border-color);
  --hover-color: var(--off-white);
  background-color: var(--background-color);
  border: solid var(--border-width) var(--border-color);
  color: var(--color);
}
.tabs a.tab svg + *, .menu__item svg + *, .tag__link svg + *, .link--inverse svg + *,
ul:not(.link-list) a:not([class]) svg + *, a:not([class]) svg + *,
.link--breadcrumb svg + *,
.link svg + *, .button svg + *, .cta svg + *, .accordion__control svg + * {
  margin-left: 0.25em;
}
.tabs a.tab svg + .screen-reader-text, .menu__item svg + .screen-reader-text, .tag__link svg + .screen-reader-text, .link--inverse svg + .screen-reader-text, a:not([class]) svg + .screen-reader-text,
.link--breadcrumb svg + .screen-reader-text,
.link svg + .screen-reader-text, .button svg + .screen-reader-text, .cta svg + .screen-reader-text, .accordion__control svg + .screen-reader-text {
  margin-right: 0;
}
.tabs a.tab * + svg, .menu__item * + svg, .tag__link * + svg, .link--inverse * + svg,
ul:not(.link-list) a:not([class]) * + svg, a:not([class]) * + svg,
.link--breadcrumb * + svg,
.link * + svg, .button * + svg, .cta * + svg, .accordion__control * + svg {
  margin-left: 0.25em;
}
.tabs a.tab .screen-reader-text + svg, .menu__item .screen-reader-text + svg, .tag__link .screen-reader-text + svg, .link--inverse .screen-reader-text + svg, a:not([class]) .screen-reader-text + svg,
.link--breadcrumb .screen-reader-text + svg,
.link .screen-reader-text + svg, .button .screen-reader-text + svg, .cta .screen-reader-text + svg, .accordion__control .screen-reader-text + svg {
  margin-left: 0;
}
.tabs a.tab:hover, .menu__item:hover, .tag__link:hover, .link--inverse:hover,
ul:not(.link-list) a:hover:not([class]), a:hover:not([class]),
.link--breadcrumb:hover,
.link:hover, .button:hover, .cta:hover, .accordion__control:hover {
  background-color: var(--hover-background-color);
  color: var(--hover-color, var(--color));
}
.tabs a.tab:focus, .menu__item:focus, .tag__link:focus, .link--inverse:focus,
ul:not(.link-list) a:focus:not([class]), a:focus:not([class]),
.link--breadcrumb:focus,
.link:focus, .button:focus, .cta:focus, .accordion__control:focus {
  background-color: var(--focus-background-color);
  border-color: var(--parent-background-color);
  box-shadow: var(--focus-box-shadow);
  color: var(--focus-color, var(--color));
  outline: none;
}
.tabs a.tab:active, .menu__item:active, .tag__link:active, .link--inverse:active,
ul:not(.link-list) a:active:not([class]), a:active:not([class]),
.link--breadcrumb:active,
.link:active, .button:active, .cta:active, .accordion__control:active {
  background-color: var(--active-background-color);
  box-shadow: var(--active-box-shadow);
  color: var(--active-color, var(--color));
}
.tabs a.tab:disabled, .menu__item:disabled, .tag__link:disabled, .link--inverse:disabled,
ul:not(.link-list) a:disabled:not([class]), a:disabled:not([class]),
.link--breadcrumb:disabled,
.link:disabled, .button:disabled, .cta:disabled, .accordion__control:disabled {
  background-color: var(--disabled-background-color);
  border-color: var(--disabled-border-color);
  color: var(--disabled-color);
}

button::-moz-focus-inner,
input[type=reset]::-moz-focus-inner,
input[type=button]::-moz-focus-inner,
input[type=submit]::-moz-focus-inner,
input[type=file] > input[type=button]::-moz-focus-inner {
  border: none;
}

.spacer {
  height: 1.5rem;
}

.separator {
  display: inline-block;
  padding-left: 0.5ch;
  text-indent: 0;
  transform: translate(0, -0.5ch);
}

:root {
  --offset: 1.875rem;
  --columns: 4;
  --gutter: 1.875rem;
  --grid-color: rgba(48, 207, 201, 0.2);
  --repeating-width: calc(100% / var(--columns));
  --column-width: calc((100% / var(--columns)) - var(--gutter));
  --background-width: calc(100% + var(--gutter));
  --background-columns: repeating-linear-gradient(to right, var(--grid-color), var(--grid-color) var(--column-width), transparent var(--column-width), transparent var(--repeating-width));
}

@media (min-width: 37.5rem) {
  :root {
    --columns: 8;
  }
}
@media (min-width: 80rem) {
  :root {
    --columns: 12;
    --max-width: 72.5rem;
  }
}
@media (min-width: 120rem) {
  :root {
    --columns: 16;
    --max-width: 98.125rem;
  }
}
html.grid::before {
  background-image: var(--background-columns);
  background-size: var(--background-width) 100%;
  bottom: 0;
  content: "";
  left: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: var(--max-width);
  min-height: 100vh;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  width: calc(100% - (2 * var(--offset)));
  z-index: 1000;
}

* {
  box-sizing: border-box;
}

body {
  align-items: center;
  color: var(--dark-mint-500);
  display: flex;
  flex-direction: column;
  font-family: "Noto Serif", sans-serif;
  margin: 0;
  min-height: 100vh;
  padding: 0;
}

.container {
  width: 100%;
}

.content {
  margin: 0 auto;
  max-width: 101.875rem;
  padding: 0 1.875rem;
  width: 100%;
}
@supports (display: grid) {
  .content {
    column-gap: 1.875rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
}

[inert] {
  opacity: 0.25;
}

main {
  margin: 0 auto;
  padding-bottom: 6.25rem;
  position: relative;
  width: calc(((100% - ((4 - 1) * 1.875rem)) * 1) + (3 * 1.875rem));
}
@supports (display: grid) {
  main {
    grid-column: 1/5;
    width: 100%;
  }
}

.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important;
}

@media (min-width: 37.5rem) {
  @supports (display: grid) {
    .content {
      grid-template-columns: repeat(8, 1fr);
    }
  }
  main {
    width: calc(((100% - ((8 - 1) * 1.875rem)) * 1) + (7 * 1.875rem));
    padding-bottom: 8.125rem;
  }
  @supports (display: grid) {
    main {
      grid-column: 1/9;
      width: 100%;
    }
  }
}
@media (min-width: 80rem) {
  .content {
    max-width: 72.5rem;
    padding: 0;
  }
  @supports (display: grid) {
    .content {
      grid-template-columns: repeat(12, 1fr);
    }
  }

  main {
    width: calc(((100% - ((12 - 1) * 1.875rem)) * 1) + (11 * 1.875rem));
  }
  @supports (display: grid) {
    main {
      grid-column: 1/13;
      width: 100%;
    }
  }
}
@media (min-width: 120rem) {
  .content {
    max-width: 98.125rem;
  }
  @supports (display: grid) {
    .content {
      grid-template-columns: repeat(16, 1fr);
    }
  }

  main {
    width: calc(((100% - ((16 - 1) * 1.875rem)) * 1) + (15 * 1.875rem));
  }
  @supports (display: grid) {
    main {
      grid-column: 1/17;
      width: 100%;
    }
  }
}
.align-right {
  text-align: right;
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

:root {
  --blue-300: #30cfc9;
  --blue-200: #c5fdf9;
  --blue-150: #c5d1ce;
  --blue-100: #edf5f3;
  --blue-50: #e3eae8;
  --green-300: #45d385;
  --green-200: #c9f8db;
  --red-400: #ff621a;
  --red-300: #ffa47a;
  --red-200: #fdc2a7;
  --yellow-600: #e8aa00;
  --yellow-500: #face00;
  --yellow-400: #fceeb0;
  --grey-400: #b2b2a7;
  --grey-300: #d5d5cf;
  --grey-200: #f0efef;
  --off-white: #f9f9f7;
  --white: #fff;
  --blue-700: #1c4342;
  --blue-600: #18514f;
  --blue-500: #16605d;
  --blue-400: #1d7c79;
  --green-500: #076431;
  --green-400: #0b8441;
  --red-500: #973102;
  --dark-mint-500: #203131;
  --dark-mint-400: #294040;
  --grey-500: #707070;
  --grey-600: #585850;
  --black: #000;
  --parent-background-color: var(--white);
}

.has-blue-300-color {
  color: var(--blue-300);
}

.has-blue-300-background-color {
  --parent-background-color: var(--blue-300);
  background: var(--blue-300);
  color: var(--dark-mint-500);
}

.has-blue-200-color {
  color: var(--blue-200);
}

.has-blue-200-background-color {
  --parent-background-color: var(--blue-200);
  background: var(--blue-200);
  color: var(--dark-mint-500);
}

.has-blue-150-color {
  color: var(--blue-150);
}

.has-blue-150-background-color {
  --parent-background-color: var(--blue-150);
  background: var(--blue-150);
  color: var(--dark-mint-500);
}

.has-blue-100-color {
  color: var(--blue-100);
}

.has-blue-100-background-color {
  --parent-background-color: var(--blue-100);
  background: var(--blue-100);
  color: var(--dark-mint-500);
}

.has-blue-50-color {
  color: var(--blue-50);
}

.has-blue-50-background-color {
  --parent-background-color: var(--blue-50);
  background: var(--blue-50);
  color: var(--dark-mint-500);
}

.has-green-300-color {
  color: var(--green-300);
}

.has-green-300-background-color {
  --parent-background-color: var(--green-300);
  background: var(--green-300);
  color: var(--dark-mint-500);
}

.has-green-200-color {
  color: var(--green-200);
}

.has-green-200-background-color {
  --parent-background-color: var(--green-200);
  background: var(--green-200);
  color: var(--dark-mint-500);
}

.has-red-400-color {
  color: var(--red-400);
}

.has-red-400-background-color {
  --parent-background-color: var(--red-400);
  background: var(--red-400);
  color: var(--dark-mint-500);
}

.has-red-300-color {
  color: var(--red-300);
}

.has-red-300-background-color {
  --parent-background-color: var(--red-300);
  background: var(--red-300);
  color: var(--dark-mint-500);
}

.has-red-200-color {
  color: var(--red-200);
}

.has-red-200-background-color {
  --parent-background-color: var(--red-200);
  background: var(--red-200);
  color: var(--dark-mint-500);
}

.has-yellow-600-color {
  color: var(--yellow-600);
}

.has-yellow-600-background-color {
  --parent-background-color: var(--yellow-600);
  background: var(--yellow-600);
  color: var(--dark-mint-500);
}

.has-yellow-500-color {
  color: var(--yellow-500);
}

.has-yellow-500-background-color {
  --parent-background-color: var(--yellow-500);
  background: var(--yellow-500);
  color: var(--dark-mint-500);
}

.has-yellow-400-color {
  color: var(--yellow-400);
}

.has-yellow-400-background-color {
  --parent-background-color: var(--yellow-400);
  background: var(--yellow-400);
  color: var(--dark-mint-500);
}

.has-grey-400-color {
  color: var(--grey-400);
}

.has-grey-400-background-color {
  --parent-background-color: var(--grey-400);
  background: var(--grey-400);
  color: var(--dark-mint-500);
}

.has-grey-300-color {
  color: var(--grey-300);
}

.has-grey-300-background-color {
  --parent-background-color: var(--grey-300);
  background: var(--grey-300);
  color: var(--dark-mint-500);
}

.has-grey-200-color {
  color: var(--grey-200);
}

.has-grey-200-background-color {
  --parent-background-color: var(--grey-200);
  background: var(--grey-200);
  color: var(--dark-mint-500);
}

.has-off-white-color {
  color: var(--off-white);
}

.has-off-white-background-color {
  --parent-background-color: var(--off-white);
  background: var(--off-white);
  color: var(--dark-mint-500);
}

.has-white-color {
  color: var(--white);
}

.has-white-background-color {
  --parent-background-color: var(--white);
  background: var(--white);
  color: var(--dark-mint-500);
}

.has-blue-700-color {
  color: var(--blue-700);
}

.has-blue-700-background-color {
  --parent-background-color: var(--blue-700);
  background: var(--blue-700);
  color: var(--white);
}

.has-blue-600-color {
  color: var(--blue-600);
}

.has-blue-600-background-color {
  --parent-background-color: var(--blue-600);
  background: var(--blue-600);
  color: var(--white);
}

.has-blue-500-color {
  color: var(--blue-500);
}

.has-blue-500-background-color {
  --parent-background-color: var(--blue-500);
  background: var(--blue-500);
  color: var(--white);
}

.has-blue-400-color {
  color: var(--blue-400);
}

.has-blue-400-background-color {
  --parent-background-color: var(--blue-400);
  background: var(--blue-400);
  color: var(--white);
}

.has-green-500-color {
  color: var(--green-500);
}

.has-green-500-background-color {
  --parent-background-color: var(--green-500);
  background: var(--green-500);
  color: var(--white);
}

.has-green-400-color {
  color: var(--green-400);
}

.has-green-400-background-color {
  --parent-background-color: var(--green-400);
  background: var(--green-400);
  color: var(--white);
}

.has-red-500-color {
  color: var(--red-500);
}

.has-red-500-background-color {
  --parent-background-color: var(--red-500);
  background: var(--red-500);
  color: var(--white);
}

.has-dark-mint-500-color {
  color: var(--dark-mint-500);
}

.has-dark-mint-500-background-color {
  --parent-background-color: var(--dark-mint-500);
  background: var(--dark-mint-500);
  color: var(--white);
}

.has-dark-mint-400-color {
  color: var(--dark-mint-400);
}

.has-dark-mint-400-background-color {
  --parent-background-color: var(--dark-mint-400);
  background: var(--dark-mint-400);
  color: var(--white);
}

.has-grey-500-color {
  color: var(--grey-500);
}

.has-grey-500-background-color {
  --parent-background-color: var(--grey-500);
  background: var(--grey-500);
  color: var(--white);
}

.has-grey-600-color {
  color: var(--grey-600);
}

.has-grey-600-background-color {
  --parent-background-color: var(--grey-600);
  background: var(--grey-600);
  color: var(--white);
}

.has-black-color {
  color: var(--black);
}

.has-black-background-color {
  --parent-background-color: var(--black);
  background: var(--black);
  color: var(--white);
}

.pc-ff--sans {
  font-family: "Noto Sans", sans-serif;
}

.pc-ff--serif {
  font-family: "Noto Serif", sans-serif;
}

.pc-ff--display {
  font-family: "Noto Sans Display", sans-serif;
}

.pc-fw--normal {
  font-weight: 400;
}

.pc-fw--semibold {
  font-weight: 600;
}

.pc-fw--bold {
  font-weight: 700;
}

.pc-fw--dark-mint-500 {
  font-weight: 900;
}

h1,
.h1 {
  font-family: "Noto Serif", sans-serif;
  font-size: 2.25rem;
  font-weight: 900;
  letter-spacing: -0.01rem;
  line-height: 1.2222222222;
  margin-bottom: 0;
  margin-top: 0;
}
h1 + *,
.h1 + *,
h1 + p,
.h1 + p,
h1 + ul,
.h1 + ul,
h1 + ol,
.h1 + ol {
  margin-top: 2rem;
}
h1.screen-reader-text + *,
.screen-reader-text.h1 + *,
h1.screen-reader-text + p,
.screen-reader-text.h1 + p,
h1.screen-reader-text + ul,
.screen-reader-text.h1 + ul,
h1.screen-reader-text + ol,
.screen-reader-text.h1 + ol {
  margin-top: 0;
}
* + h1,
* + .h1 {
  margin-top: 4.25rem;
}
@media (min-width: 37.5rem) {
  h1,
.h1 {
    font-size: 3rem;
    line-height: 1.25;
  }
  * + h1,
* + .h1 {
    margin-top: 6.25rem;
  }
}
@media (min-width: 60rem) {
  h1,
.h1 {
    font-size: 3.75rem;
    line-height: 1.2;
  }
  * + h1,
* + .h1 {
    margin-top: 8rem;
  }
  h1 + *,
.h1 + *,
h1 + p,
.h1 + p,
h1 + ul,
.h1 + ul,
h1 + ol,
.h1 + ol {
    margin-top: 2.75rem;
  }
  h1 + .subhead,
.h1 + .subhead {
    margin-top: 1.5rem;
  }
}

h2,
.h2 {
  font-family: "Noto Sans Display", sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.3333333333;
  margin-bottom: 0;
  margin-top: 0;
}
h2 + *,
.h2 + *,
h2 + p,
.h2 + p,
h2 + ul,
.h2 + ul,
h2 + ol,
.h2 + ol {
  margin-top: 1.5rem;
}
h2.screen-reader-text + *,
.screen-reader-text.h2 + *,
h2.screen-reader-text + p,
.screen-reader-text.h2 + p,
h2.screen-reader-text + ul,
.screen-reader-text.h2 + ul,
h2.screen-reader-text + ol,
.screen-reader-text.h2 + ol {
  margin-top: 0;
}
* + h2,
* + .h2 {
  margin-top: 3rem;
}
h1 + h2,
h1 + .h2 {
  margin-top: 2.25rem;
}
@media (min-width: 37.5rem) {
  h2,
.h2 {
    font-size: 1.75rem;
    line-height: 1.4285714286;
  }
  * + h2,
* + .h2 {
    margin-top: 3.5rem;
  }
  h1 + h2,
h1 + .h2 {
    margin-top: 2.75rem;
  }
}
@media (min-width: 60rem) {
  h2,
.h2 {
    font-size: 2.25rem;
    line-height: 1.3333333333;
  }
  h2 + *,
.h2 + *,
h2 + p,
.h2 + p,
h2 + ul,
.h2 + ul,
h2 + ol,
.h2 + ol {
    margin-top: 2rem;
  }
  * + h2,
* + .h2 {
    margin-top: 4rem;
  }
  h1 + h2,
h1 + .h2 {
    margin-top: 3.5rem;
  }
}

h3,
.h3 {
  font-family: "Noto Sans Display", sans-serif;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.4;
  margin-bottom: 0;
  margin-top: 0;
}
h3 + *,
.h3 + *,
h3 + p,
.h3 + p,
h3 + ul,
.h3 + ul,
h3 + ol,
.h3 + ol {
  margin-top: 1.5rem;
}
h3.screen-reader-text + *,
.screen-reader-text.h3 + *,
h3.screen-reader-text + p,
.screen-reader-text.h3 + p,
h3.screen-reader-text + ul,
.screen-reader-text.h3 + ul,
h3.screen-reader-text + ol,
.screen-reader-text.h3 + ol {
  margin-top: 0;
}
* + h3,
* + .h3 {
  margin-top: 2.5rem;
}
h2 + h3,
h2 + .h3 {
  margin-top: 1.75rem;
}
@media (min-width: 37.5rem) {
  h3,
.h3 {
    font-size: 1.5rem;
    line-height: 1.5;
  }
  * + h3,
* + .h3 {
    margin-top: 3rem;
  }
  h2 + h3,
h2 + .h3 {
    margin-top: 2rem;
  }
}
@media (min-width: 60rem) {
  h3,
.h3 {
    font-size: 1.75rem;
    line-height: 1.4285714286;
  }
  * + h3,
* + .h3 {
    margin-top: 3.5rem;
  }
  h2 + h3,
h2 + .h3 {
    margin-top: 2.75rem;
  }
}

h4,
.h4 {
  font-family: "Noto Sans", sans-serif;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.4444444444;
  margin-bottom: 0;
  margin-top: 0;
}
h4 + *,
.h4 + *,
h4 + p,
.h4 + p,
h4 + ul,
.h4 + ul,
h4 + ol,
.h4 + ol {
  margin-top: 1.5rem;
}
h4.screen-reader-text + *,
.screen-reader-text.h4 + *,
h4.screen-reader-text + p,
.screen-reader-text.h4 + p,
h4.screen-reader-text + ul,
.screen-reader-text.h4 + ul,
h4.screen-reader-text + ol,
.screen-reader-text.h4 + ol {
  margin-top: 0;
}
* + h4,
* + .h4 {
  margin-top: 2rem;
}
h3 + h4,
h3 + .h4 {
  margin-top: 1.5rem;
}
@media (min-width: 37.5rem) {
  h4,
.h4 {
    font-size: 1.25rem;
    line-height: 1.6;
  }
  * + h4,
* + .h4 {
    margin-top: 2.5rem;
  }
}
@media (min-width: 60rem) {
  h4,
.h4 {
    font-size: 1.25rem;
    line-height: 1.6;
  }
  * + h4,
* + .h4 {
    margin-top: 3rem;
  }
  h3 + h4,
h3 + .h4 {
    margin-top: 2rem;
  }
}

p {
  line-height: 1.5;
  margin-bottom: 0;
  margin-top: 0;
}

p strong {
  font-weight: 600;
}

p.p--small {
  font-size: 0.875rem;
  line-height: 1.4285714286;
}

* + p {
  margin-bottom: 0;
  margin-top: 1.5rem;
}

blockquote {
  margin-left: 3rem;
  margin-right: 0;
}

blockquote::before {
  color: var(--blue-400);
  content: "“";
  font-family: "Noto Serif", sans-serif;
  font-size: 4rem;
  margin-left: -3rem;
  margin-top: -1.125rem;
  position: absolute;
}

blockquote * {
  font-family: "Noto Sans", sans-serif;
  font-size: 1.125rem;
  font-style: italic;
  font-weight: 600;
  line-height: 1.4444444444;
}

blockquote cite {
  font-family: "Noto Serif", sans-serif;
  font-size: 1rem;
  font-style: italic;
  font-weight: 400;
}

blockquote cite::before {
  content: "— ";
}

* + blockquote {
  margin-top: 1.5rem;
}

@media (min-width: 37.5rem) {
  blockquote {
    margin-right: 3rem;
  }
}
ol,
ul {
  counter-reset: list-counter;
  padding-left: 0;
}

li {
  counter-increment: list-counter;
  margin-bottom: 0;
}

li::marker {
  color: var(--parent-color);
}

li + li {
  margin-top: 1rem;
}

* + ul,
* + ol {
  margin-bottom: 0;
  margin-top: 1.5rem;
}

.stack > * + * {
  margin-bottom: 0;
  margin-top: 1.5rem;
}

.accordion {
  position: relative;
}

.accordion__pane {
  --expanded-color: var(--white);
  --expanded-background-color: var(--blue-500);
  margin-bottom: 0;
  position: relative;
}

.accordion--inverse .accordion__pane {
  --expanded-color: var(--dark-mint-500);
  --expanded-background-color: var(--blue-200);
}

.accordion__pane:focus-within {
  z-index: 1;
}

.no-js .accordion__pane + .accordion__pane {
  margin-top: 1.875rem;
}

.accordion__heading {
  --border-width: 0.125rem;
  --color: var(--blue-500);
  border: 0;
  border-top: solid var(--border-width) var(--color);
  display: block;
  font-family: "Noto Sans", sans-serif;
  font-size: 1.25rem;
  font-weight: 700;
  text-align: left;
  width: 100%;
}

.accordion--inverse .accordion__heading {
  --color: var(--off-white);
}

.accordion__control {
  --border-width: 0.0625rem;
  --color: var(--blue-500);
  --background-color: transparent;
  --hover-color: var(--blue-400);
  --hover-background-color: var(--background-color);
  --outline-color: var(--parent-background-color);
  --active-color: var(--off-white);
  --active-background-color: var(--blue-500);
  --active-border-color: var(--blue-500);
  --focus-background-color: var(--parent-background-color);
  --focus-color: var(--blue-500);
  --focus-box-shadow:
  	0 0 0 calc(2 * var(--border-width)) var(--outline-color),
  	0 0 0 calc(4 * var(--border-width)) var(--focus-border-color);
  --active-box-shadow:
  	0 0 0 calc(2 * var(--border-width)) var(--outline-color),
  	0 0 0 calc(4 * var(--border-width)) var(--active-border-color);
  border: 0;
  border-top: solid calc(2 * var(--border-width)) var(--color);
  display: block;
  font-family: "Noto Sans", sans-serif;
  font-size: 1.25rem;
  font-weight: 700;
  height: 3.375rem;
  line-height: normal;
  padding-bottom: 0.875rem;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0.75rem;
  text-align: left;
  text-decoration: none;
  width: 100%;
}

.accordion--inverse .accordion__control {
  --color: var(--off-white);
  --background-color: transparent;
  --outline-color: var(--dark-mint-500);
  --hover-color: var(--off-white);
  --active-color: var(--parent-background-color);
  --active-background-color: var(--blue-200);
  --active-border-color: var(--blue-200);
  --focus-background-color: var(--parent-background-color);
  --focus-border-color: var(--blue-200);
  --focus-color: var(--off-white);
}

.accordion__control svg {
  color: inherit;
  float: right;
  margin-top: 0.125rem;
  transition: transform 0.3s;
}
@media (prefers-reduced-motion: reduce) {
  .accordion__control svg {
    transition: none;
  }
}

.accordion__control:hover {
  border-top: solid calc(2 * var(--border-width)) var(--blue-300);
}
.accordion__control:hover svg {
  color: var(--blue-300);
}

.accordion__control:active {
  border-top-color: var(--active-background-color);
  margin-left: -1rem;
  margin-right: -1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  width: calc(100% + 2rem);
}
.accordion__control:active svg {
  color: inherit;
}

.accordion__control:focus {
  margin-left: -1rem;
  margin-right: -1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  width: calc(100% + 2rem);
}
.accordion__control:focus svg {
  color: inherit;
}

.accordion__pane--expanded + .accordion__pane .accordion__control {
  border-top-color: transparent;
}

.accordion__control:focus:hover {
  border-top-color: var(--parent-background-color);
}

.accordion__control:focus:active,
.accordion__control:focus:hover:active {
  border-top-color: var(--active-background-color);
}

.accordion__control[aria-expanded=true] {
  --parent-background-color: var(--expanded-background-color);
  --outline-color: var(--expanded-color);
  --active-color: var(--white);
  --hover-color: var(--white);
  --background-color: var(--expanded-background-color);
  --active-background-color: var(--expanded-background-color);
  --color: var(--expanded-color);
  --focus-color: var(--off-white);
  border-top-color: transparent;
  box-shadow: 0 0 0 calc(4 * var(--border-width)) var(--expanded-background-color);
  margin-left: -1rem;
  margin-right: -1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  width: calc(100% + 2rem);
}

.accordion--inverse .accordion__control[aria-expanded=true] {
  --active-color: var(--dark-mint-500);
  --hover-color: var(--dark-mint-500);
}

.accordion__control[aria-expanded=true] svg {
  transform: rotate(-180deg);
}

.accordion__control[aria-expanded=true]:hover {
  border-top-color: transparent;
}
.accordion__control[aria-expanded=true]:hover svg {
  color: inherit;
}

.accordion__control[aria-expanded=true]:active,
.accordion__control[aria-expanded=true]:focus {
  border-top-color: var(--background-color);
}

.accordion__control[aria-expanded=true]:focus {
  --outline-color: var(--active-color);
  box-shadow: var(--focus-box-shadow);
}

.accordion__control[aria-expanded=true]:focus:active,
.accordion__control[aria-expanded=true]:focus:hover:active {
  border-top-color: transparent;
  margin-left: -1rem;
  margin-right: -1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  width: calc(100% + 2rem);
}

.accordion__content {
  margin-top: 1.25rem;
}

.accordion__control[aria-expanded] + .accordion__content {
  display: none;
}

.accordion__control[aria-expanded=true] + .accordion__content {
  background: var(--expanded-background-color);
  color: var(--expanded-color);
  display: block;
  margin-left: -1.25rem;
  margin-right: -1.25rem;
  margin-top: 0.125rem;
  padding: 1rem 1.25rem 0.875rem;
  width: calc(100% + 2.5rem);
}

.cta {
  border-radius: 0.1875rem;
  display: block;
  font-family: "Noto Sans", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  height: 2.375rem;
  max-width: 100%;
  padding: 0.375rem 0.375rem 0.25rem;
  text-align: center;
  text-decoration: underline;
  text-decoration-color: transparent;
  text-decoration-skip-ink: auto;
  text-underline-offset: 0.1875rem;
  width: 100%;
}
.cta svg {
  margin-left: 0.25em;
}
.cta:hover, .cta:active, .cta:focus {
  text-decoration: underline;
  text-decoration-color: inherit;
  text-decoration-skip-ink: auto;
  text-underline-offset: 0.1875rem;
}

.cta--inverse {
  --color: var(--blue-500);
  --outline-color: var(--blue-500);
  --background-color: var(--off-white);
  --hover-color: var(--color);
  --hover-background-color: var(--blue-50);
  --active-color: var(--off-white);
  --active-background-color: var(--parent-background-color);
  --active-border-color: var(--off-white);
  --active-box-shadow:
  	0 0 0 calc(2 * var(--border-width)) var(--active-border-color) inset,
  	0 0 0 calc(1 * var(--border-width)) var(--active-background-color),
  	0 0 0 calc(3 * var(--border-width)) var(--active-border-color);
  --focus-background-color: var(--background-color);
  --focus-color: var(--blue-500);
  --focus-border-color: var(--background-color);
  --focus-box-shadow:
  	0 0 0 calc(1 * var(--border-width)) var(--parent-background-color),
  	0 0 0 calc(3 * var(--border-width)) var(--focus-border-color);
}

hr {
  background-color: var(--blue-500);
  border: 0;
  height: 0.125rem;
}
hr.is-style-hairline {
  height: 0.0625rem;
}
hr.is-style-medium {
  height: 0.1875rem;
}
hr.is-style-thick {
  height: 1rem;
}

button::-moz-focus-inner {
  outline: none;
}

button:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--white);
}

.button {
  border-radius: 0.1875rem;
  display: block;
  font-family: "Noto Sans", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  height: auto;
  max-width: 16.625rem;
  min-height: 2.375rem;
  padding: 0.375rem 0.375rem 0.25rem;
  text-align: center;
  text-decoration: none;
  width: 100%;
}

.button--inverse {
  --color: var(--blue-500);
  --background-color: var(--off-white);
  --active-background-color: var(--blue-500);
  --active-color: var(--off-white);
  --disabled-background-color: var(--grey-200);
  --disabled-border-color: var(--grey-200);
  --disabled-color: var(--grey-400);
  --focus-background-color: var(--off-white);
  --focus-border-color: var(--off-white);
  --focus-color: var(--blue-500);
  --hover-background-color: var(--blue-50);
  --hover-color: var(--color);
}

.button--secondary {
  --color: var(--blue-500);
  --background-color: var(--white);
  --border-color: var(--blue-500);
  --active-border-color: transparent;
  --active-background-color: var(--blue-500);
  --active-color: var(--off-white);
  --hover-background-color: var(--grey-200);
  --hover-color: var(--blue-500);
  --disabled-background-color: var(--grey-200);
  --disabled-border-color: var(--grey-200);
  --disabled-color: var(--grey-400);
  --focus-background-color: var(--off-white);
  --focus-border-color: var(--blue-500);
  --focus-color: var(--blue-500);
}
.button--secondary:focus {
  border-color: transparent;
}

.button--secondary.button--inverse {
  --color: var(--off-white);
  --background-color: var(--blue-500);
  --border-color: var(--off-white);
  --active-background-color: var(--off-white);
  --active-color: var(--blue-500);
  --disabled-background-color: var(--parent-background-color);
  --disabled-border-color: var(--grey-400);
  --disabled-color: var(--grey-400);
  --focus-background-color: var(--blue-500);
  --focus-border-color: var(--off-white);
  --focus-color: var(--off-white);
  --hover-border-color: var(--blue-50);
  --hover-color: var(--blue-50);
  --hover-background-color: var(--blue-700);
}

.button--borderless {
  --color: var(--blue-500);
  --background-color: transparent;
  --active-background-color: var(--blue-500);
  --active-color: var(--off-white);
  --disabled-background-color: var(--parent-background-color);
  --disabled-color: var(--grey-400);
  --focus-background-color: inherit;
  --focus-border-color: var(--color);
  --focus-color: var(--blue-500);
  --hover-background-color: var(--grey-200);
  --hover-color: var(--dark-mint-500);
  margin: 0.375rem 1.5rem 0.375rem -0.5rem;
  min-height: 1.75rem;
  padding: 0.25rem 0.375rem;
  width: auto;
}
.button--borderless:disabled {
  border-color: var(--parent-background-color);
}
.button--borderless:focus {
  border: solid var(--border-width) transparent;
}

.button--toggle,
.button--radio {
  --color: var(--blue-500);
  --background-color: var(--off-white);
  --border-color: var(--blue-500);
  --active-background-color: var(--blue-500);
  --active-color: var(--off-white);
  --active-box-shadow: 0 0 0 calc(2 * var(--border-width)) var(--parent-background-color), 0 0 0 calc(4 * var(--border-width)) var(--focus-border-color);
  --focus-background-color: var(--off-white);
  --focus-border-color: var(--color);
  --focus-color: inherit;
  --focus-box-shadow: 0 0 0 calc(1 * var(--border-width)) var(--focus-border-color) inset, 0 0 0 calc(2 * var(--border-width)) var(--parent-background-color), 0 0 0 calc(4 * var(--border-width)) var(--focus-border-color);
  --hover-color: var(--color);
  --hover-background-color: var(--grey-200);
  border-radius: 0.3125rem;
  font-weight: 400;
  margin: 0.5rem 0.5625rem;
  min-height: 2rem;
  padding: 0 0.625rem;
  width: auto;
}
.button--toggle:focus,
.button--radio:focus {
  border-color: var(--focus-border-color);
}
.button--toggle[aria-pressed=true], .button--toggle[aria-checked=true],
.button--radio[aria-pressed=true],
.button--radio[aria-checked=true] {
  --color: var(--off-white);
  --background-color: var(--blue-600);
  --border-color: var(--background-color);
  --focus-background-color: var(--background-color);
  --focus-border-color: var(--background-color);
  --focus-color: inherit;
  --focus-box-shadow: 0 0 0 calc(2 * var(--border-width)) var(--parent-background-color), 0 0 0 calc(4 * var(--border-width)) var(--focus-border-color);
  --hover-background-color: var(--blue-700);
  --hover-border-color: var(--blue-700);
}
.button--toggle[aria-pressed=true]:hover, .button--toggle[aria-checked=true]:hover,
.button--radio[aria-pressed=true]:hover,
.button--radio[aria-checked=true]:hover {
  border-color: var(--hover-border-color);
}
.button--toggle[aria-pressed=true]:active, .button--toggle[aria-checked=true]:active,
.button--radio[aria-pressed=true]:active,
.button--radio[aria-checked=true]:active {
  border-color: transparent;
}

.button--toggle.button--inverse,
.button--radio.button--inverse {
  --color: var(--off-white);
  --background-color: var(--blue-500);
  --border-color: var(--off-white);
  --active-background-color: var(--off-white);
  --active-color: var(--blue-500);
  --active-box-shadow: 0 0 0 calc(2 * var(--border-width)) var(--parent-background-color), 0 0 0 calc(4 * var(--border-width)) var(--focus-border-color);
  --focus-background-color: var(--blue-500);
  --focus-border-color: var(--color);
  --focus-color: inherit;
  --focus-box-shadow: 0 0 0 calc(1 * var(--border-width)) var(--focus-border-color) inset, 0 0 0 calc(2 * var(--border-width)) var(--parent-background-color), 0 0 0 calc(4 * var(--border-width)) var(--focus-border-color);
  --hover-background-color: var(--blue-600);
}
.button--toggle.button--inverse:active,
.button--radio.button--inverse:active {
  border-color: transparent;
}
.button--toggle.button--inverse[aria-pressed=true], .button--toggle.button--inverse[aria-checked=true],
.button--radio.button--inverse[aria-pressed=true],
.button--radio.button--inverse[aria-checked=true] {
  --color: var(--blue-700);
  --background-color: var(--blue-50);
  --border-color: var(--background-color);
  --focus-background-color: var(--background-color);
  --focus-border-color: var(--background-color);
  --focus-color: inherit;
  --focus-box-shadow: 0 0 0 calc(2 * var(--border-width)) var(--parent-background-color), 0 0 0 calc(4 * var(--border-width)) var(--focus-border-color);
  --hover-background-color: var(--blue-150);
  --hover-border-color: var(--blue-150);
}
.button--toggle.button--inverse[aria-pressed=true]:hover, .button--toggle.button--inverse[aria-checked=true]:hover,
.button--radio.button--inverse[aria-pressed=true]:hover,
.button--radio.button--inverse[aria-checked=true]:hover {
  border-color: var(--hover-border-color);
}
.button--toggle.button--inverse[aria-pressed=true]:active, .button--toggle.button--inverse[aria-checked=true]:active,
.button--radio.button--inverse[aria-pressed=true]:active,
.button--radio.button--inverse[aria-checked=true]:active {
  border-color: transparent;
}

.radio-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: -0.5625rem;
}

.button[disabled] {
  background-color: var(--disabled-background-color);
  color: var(--disabled-color);
}
.button[disabled]:active, .button[disabled]:focus {
  box-shadow: none;
}

.align-right .button--borderless {
  display: inline-block;
  margin: 0 -0.5rem 0 1.5rem;
}

.button--borderless.button--inverse {
  --color: var(--off-white);
  --active-color: var(--blue-600);
  --active-background-color: var(--off-white);
  --focus-background-color: transparent;
  --focus-color: var(--off-white);
  --hover-background-color: var(--blue-600);
  --hover-color: var(--off-white);
}

.button--borderless.button--destructive {
  --color: var(--red-500);
  --active-background-color: var(--red-500);
  --active-color: var(--white);
  --focus-color: var(--red-500);
  --hover-color: var(--dark-mint-500);
}

.button--borderless.button--destructive.button--inverse {
  --color: var(--red-200);
  --active-background-color: var(--red-200);
  --active-color: var(--blue-600);
  --focus-border-color: var(--red-200);
  --focus-color: var(--red-200);
  --hover-color: var(--red-200);
  --hover-background-color: var(--blue-600);
}

.button--tag-button {
  --active-background-color: var(--off-white);
  --active-color: var(--blue-700);
  --focus-border-color: var(--blue-700);
  --focus-background-color: var(--blue-700);
  --hover-background-color: var(--blue-700);
  border-radius: 0.9375rem;
  font-family: "Noto Sans", sans-serif;
  font-size: 0.875rem;
  font-weight: 600;
  height: auto;
  max-width: 100%;
  min-height: 1.875rem;
  padding: 0.1875rem 0.5rem 0.1875rem 0.75rem;
  text-align: center;
  text-decoration: none;
  width: auto;
}

.button--disc {
  --color: var(--blue-500);
  --background-color: var(--blue-50);
  --active-border-color: transparent;
  --active-background-color: var(--blue-500);
  --active-color: var(--blue-50);
  --disabled-background-color: var(--grey-200);
  --disabled-border-color: var(--parent-background-color);
  --disabled-color: var(--grey-400);
  --focus-background-color: var(--background-color);
  --focus-border-color: var(--color);
  --focus-color: var(--color);
  --hover-background-color: var(--blue-150);
  --hover-color: var(--color);
  align-items: center;
  border-radius: 50%;
  display: inline-block;
  display: inline-flex;
  flex-direction: column;
  font-size: 1rem;
  height: 2.125rem;
  justify-content: center;
  margin: 0;
  min-height: 2.125rem;
  padding: 0;
  width: 2.125rem;
}
.button--disc svg {
  margin-bottom: 0;
}
.button--disc:focus {
  border: none;
}

.button--disc.button--inverse {
  --color: var(--off-white);
  --background-color: var(--blue-700);
  --active-background-color: var(--off-white);
  --active-color: var(--blue-700);
  --disabled-color: var(--blue-700);
  --disabled-background-color: var(--blue-500);
  --disabled-border-color: var(--blue-700);
  --hover-background-color: var(--black);
}

.global-nav {
  --parent-background: var(--blue-600);
  background-color: var(--blue-600);
  height: 2.5rem;
  width: 100%;
}

.global-nav__list {
  height: 2.5rem;
  list-style: none;
  margin: 0 auto;
  width: 100%;
}

.global-nav__list-item {
  display: none;
  height: 2.5rem;
}

.global-nav__list-item + .global-nav__list-item {
  margin-top: 0;
}

.global-nav__list-item--first {
  display: inline-block;
}

.global-nav__link {
  color: var(--white);
  display: inline-block;
  font-family: "Noto Sans", sans-serif;
  font-size: 0.875rem;
  height: 2.5rem;
  padding: 0.625rem;
  position: relative;
  text-decoration: none;
  vertical-align: middle;
}
.global-nav__link[rel=home], .global-nav__link[rel=current] {
  font-weight: 600;
}
.global-nav__link[rel=home]::after, .global-nav__link[rel=current]::after {
  background-color: var(--white);
  bottom: 0;
  content: "";
  display: block;
  height: 0.125rem;
  position: absolute;
  width: calc(100% - 1.25rem);
}
.global-nav__link:hover {
  background: var(--white);
  color: var(--blue-600);
  text-decoration: underline;
  text-decoration-skip-ink: auto;
  text-decoration-thickness: 0.0625rem;
  text-underline-offset: 0.1875rem;
}
.global-nav__link:focus {
  background: var(--white);
  box-shadow: 0 0 0 0.125rem var(--white) inset, 0 0 0 0.25rem var(--blue-600) inset;
  color: var(--blue-600);
  outline: none;
  text-decoration: underline;
  text-decoration-skip-ink: auto;
  text-decoration-thickness: 0.0625rem;
  text-underline-offset: 0.1875rem;
}

@media (min-width: 37.5rem) {
  .global-nav__list-item {
    display: inline-block;
  }
}
@media (min-width: 60rem) {
  .global-nav {
    grid-column: 1/4;
  }
}
@media (min-width: 80rem) {
  .global-nav__list {
    max-width: 72.5rem;
  }
}
@media (min-width: 120rem) {
  .global-nav__list {
    max-width: 98.125rem;
  }
}
.icon {
  height: 1.25em;
  margin-bottom: -0.25em;
  width: 1.25em;
}

.icon.icon--md {
  height: 1.5em;
  width: 1.5em;
}

.icon.icon--lg {
  height: 2em;
  width: 2em;
}

.icon.icon--xl {
  height: 2.5em;
  width: 2.5em;
}

.screen-reader-text + svg {
  margin-left: 0;
}

img {
  border-style: none;
  display: block;
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}

img:not([alt]) {
  filter: blur(0.625rem);
}

.form {
  --border-width: 0.0625rem;
  --text-border-color-default: var(--grey-500);
  --text-border-color-disabled: var(--grey-400);
  --text-border-color-hover: var(--blue-500);
  --text-border-color-focus: var(--blue-500);
  --text-border-color-error: var(--red-500);
  --text-border-color-error-hover: var(--red-500);
  --text-border-color-error-focus: var(--red-500);
  --text-value-color-disabled: var(--grey-500);
  --text-value-color-error: var(--red-500);
  --text-background-color-disabled: var(--grey-200);
  --input-group--error-color: var(--red-500);
  --text-default-box-shadow: 0 0 0 var(--border-width) var(--text-border-color-default) inset;
  --text-disabled-box-shadow: 0 0 0 var(--border-width) var(--text-border-color-disabled) inset;
  --text-error-box-shadow: 0 0 0 var(--border-width) var(--text-border-color-error) inset;
  --text-focus-box-shadow:
  	0 0 0 calc(2 * var(--border-width)) var(--text-border-color-focus) inset,
  	0 0 0 calc(1 * var(--border-width)) var(--parent-background-color),
  	0 0 0 calc(3 * var(--border-width)) var(--text-border-color-focus);
  --text-hover-box-shadow: 0 0 0 calc(2 * var(--border-width)) var(--text-border-color-hover) inset;
  --text-error-hover-box-shadow: 0 0 0 calc(2 * var(--border-width)) var(--text-border-color-error-hover) inset;
  --text-error-focus-box-shadow:
  	0 0 0 calc(2 * var(--border-width)) var(--text-border-color-error-focus) inset,
  	0 0 0 calc(1 * var(--border-width)) var(--parent-background-color),
  	0 0 0 calc(3 * var(--border-width)) var(--text-border-color-error-focus);
  --checkbox-radio-hover-color: var(--blue-500);
  --checkbox-radio-active-color: var(--blue-150);
  --checkbox-radio-border-color: var(--grey-500);
  --checkbox-radio-checked-background-color: var(--blue-500);
  --checkbox-radio-focus-border-color: var(--blue-500);
  --checkbox-radio-focus-hover-background-color: var(--off-white);
  --checkbox-radio-hover-box-shadow: 0 0 0 calc(1 * var(--border-width)) var(--checkbox-radio-hover-color) inset;
  --checkbox-radio-focus-box-shadow:
  	0 0 0 calc(2 * var(--border-width)) var(--parent-background-color),
  	0 0 0 calc(4 * var(--border-width)) var(--checkbox-radio-focus-border-color),
  	0 0 0 calc(1 * var(--border-width)) var(--checkbox-radio-focus-border-color) inset;
  --checkbox-checked-hover-box-shadow: 0 0 0 var(--border-width) var(--checkbox-radio-focus-border-color);
  --radio-checked-box-shadow:
  	0 0 0 var(--border-width) var(--checkbox-radio-focus-border-color) inset,
  	0 0 0 calc(3 * var(--border-width)) var(--parent-background-color) inset;
  --radio-checked-focus-box-shadow:
  	0 0 0 calc(2 * var(--border-width)) var(--parent-background-color),
  	0 0 0 calc(4 * var(--border-width)) var(--checkbox-radio-focus-border-color),
  	0 0 0 var(--border-width) var(--checkbox-radio-focus-border-color) inset,
  	0 0 0 calc(3 * var(--border-width)) var(--parent-background-color) inset;
  --radio-checked-hover-box-shadow:
  	0 0 0 calc(1 * var(--border-width)) var(--checkbox-radio-hover-color) inset,
  	0 0 0 calc(3 * var(--border-width)) var(--parent-background-color) inset;
}

.form--inverse {
  --text-border-color-default: var(--grey-500);
  --text-border-color-disabled: var(--grey-500);
  --text-border-color-hover: var(--blue-300);
  --text-border-color-focus: var(--off-white);
  --text-border-color-error: var(--red-300);
  --text-border-color-error-hover: var(--red-300);
  --text-border-color-error-focus: var(--red-300);
  --text-value-color-disabled: var(--grey-500);
  --text-value-color-error: var(--red-500);
  --text-background-color-disabled: var(--grey-300);
  --input-group--error-color: var(--red-200);
  --text-focus-box-shadow:
  	0 0 0 calc(1 * var(--border-width)) var(--parent-background-color),
  	0 0 0 calc(3 * var(--border-width)) var(--text-border-color-focus);
  --text-error-focus-box-shadow:
  	0 0 0 calc(1 * var(--border-width)) var(--parent-background-color),
  	0 0 0 calc(3 * var(--border-width)) var(--text-border-color-error-hover);
  --checkbox-radio-hover-color: var(--blue-300);
  --checkbox-radio-active-color: var(--blue-400);
  --checkbox-radio-border-color: var(--off-white);
  --checkbox-radio-checked-background-color: var(--off-white);
  --checkbox-radio-focus-border-color: var(--blue-300);
  --checkbox-radio-focus-hover-background-color: default;
}

.input-group {
  display: block;
  list-style: none;
  position: relative;
  width: auto;
}

.input-group + .input-group {
  margin-top: 2.8125rem;
}

.input-group > * + * {
  margin-top: 0.75rem;
}

label + input,
label + textarea,
label + select,
.input-group__error,
* + .helptext {
  margin-top: 0.75rem;
}

input + label,
textarea + label,
select + label,
.helptext + label {
  margin-top: 3.75rem;
}

label {
  display: block;
  font-family: "Noto Serif", sans-serif;
  font-weight: 700;
  line-height: 1.5;
}

label[hidden] {
  display: none;
}

input[type=checkbox] + label,
input[type=radio] + label {
  display: inline-block;
  margin-top: 0;
}

input {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: solid var(--border-width) transparent;
  border-radius: 0.21875rem;
  box-shadow: var(--text-default-box-shadow);
  display: block;
  font-family: "Noto Serif", sans-serif;
  font-size: 1rem;
  height: 2.5rem;
  line-height: 1.5;
  max-width: 100%;
  outline: none;
  padding: 0.625rem 0.625rem 0.4375rem;
  transition: border 0.1s ease-out, background-color 0.1s ease-out, box-shadow 0.1s ease-out;
  width: 100%;
}
@media (prefers-reduced-motion: reduce) {
  input {
    transition: none;
  }
}

@media (min-width: 37.5rem) {
  input[type=number] {
    max-width: 50%;
  }
}

.form--inverse input {
  border-color: var(--parent-background-color);
}

input:hover {
  box-shadow: var(--text-hover-box-shadow);
}

input.input--error {
  box-shadow: var(--text-error-box-shadow);
  color: var(--text-value-color-error);
}

input:focus,
input:focus:hover {
  box-shadow: var(--text-focus-box-shadow);
}

input[disabled],
input[disabled]:hover {
  background-color: var(--text-background-color-disabled);
  box-shadow: var(--text-disabled-box-shadow);
  color: var(--text-value-color-disabled);
}

input.input--error:hover {
  box-shadow: var(--text-error-hover-box-shadow);
}

input.input--error:focus {
  box-shadow: var(--text-error-focus-box-shadow);
}

textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: solid var(--border-width) transparent;
  border-radius: 0.21875rem;
  box-shadow: var(--text-default-box-shadow);
  display: block;
  font-family: "Noto Serif", sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  max-width: 100%;
  min-height: 7.375rem;
  outline: none;
  padding: 0.625rem 0.625rem 0.4375rem;
  resize: vertical;
  transition: border 0.1s ease-out, background-color 0.1s ease-out, box-shadow 0.1s ease-out;
  width: 100%;
}
@media (prefers-reduced-motion: reduce) {
  textarea {
    transition: none;
  }
}

.form--inverse textarea {
  border-color: var(--parent-background-color);
}

textarea:hover {
  box-shadow: var(--text-hover-box-shadow);
}

textarea.input--error {
  box-shadow: var(--text-error-box-shadow);
  color: var(--text-value-color-error);
}

textarea:focus,
textarea:focus:hover {
  box-shadow: var(--text-focus-box-shadow);
}

textarea[disabled],
textarea[disabled]:hover {
  background-color: var(--text-background-color-disabled);
  box-shadow: var(--text-disabled-box-shadow);
  color: var(--text-value-color-disabled);
}

textarea.input--error:hover {
  box-shadow: var(--text-error-hover-box-shadow);
}

textarea.input--error:focus {
  box-shadow: var(--text-error-focus-box-shadow);
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url("../images/chevron-down.svg");
  background-position: calc(100% - 0.5rem) center;
  background-repeat: no-repeat;
  background-size: 1.25em;
  border: solid var(--border-width) transparent;
  border-radius: 0.21875rem;
  box-shadow: var(--text-default-box-shadow);
  display: block;
  font-family: "Noto Serif", sans-serif;
  font-size: 1rem;
  height: 2.5rem;
  line-height: 1.5;
  line-height: normal;
  max-width: 100%;
  min-width: 50%;
  outline: none;
  padding: 0.40625rem 2.5rem 0.375rem 0.625rem;
  transition: border 0.1s ease-out, background-color 0.1s ease-out, box-shadow 0.1s ease-out;
  width: auto;
}
select::-ms-expand {
  display: none;
}
select::-moz-focus-inner {
  outline: none;
}
select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--dark-mint-500);
}
@media (prefers-reduced-motion: reduce) {
  select {
    transition: none;
  }
}

select[multiple] {
  background-image: none;
  height: auto;
  padding: 0.625rem 0.625rem 0.4375rem;
}

.form--inverse select {
  border-color: var(--parent-background-color);
}

select.input--error {
  background-image: url("../images/chevron-down--error.svg");
  box-shadow: var(--text-error-box-shadow);
  color: var(--text-value-color-error);
}
select.input--error:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--text-value-color-error);
}

select.input--error[multiple] {
  background-image: none;
}

select:hover {
  box-shadow: var(--text-hover-box-shadow);
}

select:focus,
select:focus:hover {
  box-shadow: var(--text-focus-box-shadow);
}

select.input--error:hover {
  box-shadow: var(--text-error-hover-box-shadow);
}

select.input--error:focus {
  box-shadow: var(--text-error-focus-box-shadow);
}

select[disabled],
select[disabled]:hover {
  background-color: var(--text-background-color-disabled);
  background-image: url("../images/chevron-down--disabled.svg");
  box-shadow: var(--text-disabled-box-shadow);
  color: var(--text-value-color-disabled);
}

select[multiple][disabled] {
  background-image: none;
}

input[type=checkbox] {
  appearance: none;
  background-color: transparent;
  border: 0;
  box-shadow: none;
  display: inline-block;
  height: auto;
  margin: 0;
  max-width: 100%;
  padding: 0;
  width: auto;
}

[role=checkbox] {
  margin-right: 2.5rem;
}

[role=checkbox] + input[type=checkbox] {
  display: none;
}

.checkbox label,
input[type=checkbox] + label,
[role=checkbox] {
  cursor: default;
  font-weight: 400;
}

input[type=checkbox]::before,
[role=checkbox]::before {
  border: 0.0625rem solid;
  border-color: var(--checkbox-radio-border-color);
  border-radius: 0.1875rem;
  content: "";
  display: block;
  float: left;
  height: 1.125rem;
  margin: 0 0.5rem -0.25rem 0;
  transition: border 0.1s ease-out, background-color 0.1s ease-out, box-shadow 0.1s ease-out;
  width: 1.125rem;
}
@media (prefers-reduced-motion: reduce) {
  input[type=checkbox]::before,
[role=checkbox]::before {
    transition: none;
  }
}

input[type=checkbox]:focus,
[role=checkbox]:focus {
  box-shadow: none;
  outline: none;
}

input[type=checkbox]::before:focus,
[role=checkbox]::before:focus {
  outline: none;
}

input[type=checkbox]:disabled + label {
  color: var(--text-value-color-disabled);
}

input[type=checkbox]:checked::before,
[role=checkbox][aria-checked=true]::before {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10.572" height="7.742" viewBox="0 0 10.572 7.742"><path d="M.681 3.812l3.148 2.931L9.864.708" fill="none" stroke="%23f9f9f7" stroke-linejoin="round" class="stroke" stroke-width="2"/></svg>');
}

[role=checkbox][aria-checked=mixed]::before {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 2"><path fill="%23f9f9f7" d="M0,1A1,1,0,0,0,1,2H7A1,1,0,0,0,7,0H1A1,1,0,0,0,0,1Z"/></svg>');
  background-size: 0.625rem 0.15625rem;
}

input[type=checkbox]:hover::before,
[role=checkbox]:hover::before {
  background-color: var(--checkbox-radio-focus-hover-background-color);
  border-color: var(--checkbox-radio-hover-color);
  box-shadow: var(--checkbox-radio-hover-box-shadow);
}

input[type=checkbox]:active::before,
[role=checkbox]:active::before {
  background-color: var(--checkbox-radio-active-color);
  border-color: var(--checkbox-radio-hover-color);
  box-shadow: var(--checkbox-radio-hover-box-shadow);
}

input[type=checkbox]:focus::before,
[role=checkbox]:focus::before {
  border-color: var(--checkbox-radio-focus-border-color);
  box-shadow: var(--checkbox-radio-focus-box-shadow);
}

input[type=checkbox]:checked::before,
[role=checkbox][aria-checked=mixed]::before,
[role=checkbox][aria-checked=true]::before {
  background-color: var(--checkbox-radio-checked-background-color);
  background-position: center;
  background-repeat: no-repeat;
  border: 0.0625rem solid var(--checkbox-radio-checked-background-color);
}

input[type=checkbox]:disabled::before {
  border-color: var(--text-border-color-disabled);
}

input[type=checkbox]:disabled:hover {
  background-color: transparent;
  box-shadow: none;
  color: transparent;
}

input[type=checkbox]:disabled:hover::before,
[role=checkbox]:disabled:hover::before {
  background: initial;
  box-shadow: none;
}

input[type=checkbox]:disabled:active::before,
[role=checkbox]:disabled:active::before {
  background: initial;
  box-shadow: none;
}

.form--inverse [role=checkbox][aria-checked=mixed]::before {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 2"><path fill="%23000000" d="M0,1A1,1,0,0,0,1,2H7A1,1,0,0,0,7,0H1A1,1,0,0,0,0,1Z"/></svg>');
}

.form--inverse input[type=checkbox]:checked::before,
.form--inverse [role=checkbox][aria-checked=true]::before {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10.572" height="7.742" viewBox="0 0 10.572 7.742"><path d="M.681 3.812l3.148 2.931L9.864.708" fill="none" stroke="%23000000" stroke-linejoin="round" class="stroke" stroke-width="2"/></svg>');
}

.form--inverse input[type=checkbox]:checked:focus::before,
.form--inverse [role=checkbox][aria-checked=true]:focus::before {
  box-shadow: 0 0 0 calc(2 * var(--border-width)) var(--parent-background-color), 0 0 0 calc(4 * var(--border-width)) var(--checkbox-radio-focus-border-color);
}

.input-group__descendant {
  margin-left: 0.625rem;
  margin-top: 0.75rem;
}

input[type=radio] {
  appearance: none;
  background-color: transparent;
  border: 0;
  box-shadow: none;
  display: inline-block;
  height: auto;
  margin: 0;
  max-width: 100%;
  padding: 0;
  width: auto;
}

.radio label,
input[type=radio] + label {
  font-weight: 400;
}

input[type=radio]::before {
  border: 0.0625rem solid;
  border-color: var(--checkbox-radio-border-color);
  border-radius: 100%;
  content: "";
  display: block;
  float: left;
  height: 1.125rem;
  margin: 0 0.5rem -0.25rem 0;
  transition: border 0.1s ease-out, background-color 0.1s ease-out, box-shadow 0.1s ease-out;
  width: 1.125rem;
}
@media (prefers-reduced-motion: reduce) {
  input[type=radio]::before {
    transition: none;
  }
}

input[type=radio]:focus {
  box-shadow: none;
  outline: none;
}

input[type=radio]::before:focus {
  outline: none;
}

input[type=radio]:disabled + label {
  color: var(--text-value-color-disabled);
}

input[type=radio]:hover::before {
  background-color: var(--checkbox-radio-focus-hover-background-color);
  border-color: var(--checkbox-radio-hover-color);
  box-shadow: var(--checkbox-radio-hover-box-shadow);
}

input[type=radio]:active::before {
  background-color: var(--checkbox-radio-active-color);
  border-color: var(--checkbox-radio-hover-color);
  box-shadow: var(--checkbox-radio-hover-box-shadow);
}

input[type=radio]:focus::before {
  border-color: var(--checkbox-radio-focus-border-color);
  box-shadow: var(--checkbox-radio-focus-box-shadow);
}

input[type=radio]:checked::before {
  background: var(--checkbox-radio-checked-background-color);
  border-color: var(--checkbox-radio-focus-border-color);
  box-shadow: var(--radio-checked-box-shadow);
}

input[type=radio]:disabled::before {
  border-color: var(--text-border-color-disabled);
}

input[type=radio]:disabled:hover {
  background: initial;
  box-shadow: none;
}

input[type=radio]:disabled:hover::before {
  background: initial;
  box-shadow: none;
}

input[type=radio]:disabled:active::before {
  background: initial;
  box-shadow: none;
}

input[type=radio]:checked:hover::before {
  border-color: var(--checkbox-radio-hover-color);
  box-shadow: var(--radio-checked-hover-box-shadow);
}

input[type=radio]:checked:focus::before {
  box-shadow: var(--radio-checked-focus-box-shadow);
}

input[type=radio]:checked:focus:hover {
  box-shadow: none;
}

[type=submit] {
  max-width: 100%;
}

.input-group__description,
.helptext {
  display: block;
  font-size: 0.875rem;
  font-style: italic;
  line-height: 1.7142857143;
}

.input-group__error {
  color: var(--input-group--error-color);
  display: flex;
  flex-direction: row;
  font-family: "Noto Sans", sans-serif;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.4285714286;
}
.input-group__error svg {
  margin-right: 0.25rem;
}

.link--inverse, ol a:not([class]),
blockquote a:not([class]),
p a:not([class]),
ul:not(.link-list) a:not([class]), a:not([class]),
.link--breadcrumb,
.link {
  --focus-box-shadow:
  	0 0 0 calc(3 * var(--border-width)) var(--focus-background-color),
  	0 0 0 calc(5 * var(--border-width)) var(--focus-color);
  --active-box-shadow:
  	0 0 0 calc(1 * var(--border-width)) var(--active-border-color),
  	0 0 0 calc(3 * var(--border-width)) var(--parent-background-color),
  	0 0 0 calc(5 * var(--border-width)) var(--active-border-color);
  --background-color: var(--parent-background-color);
  --hover-background-color: var(--parent-background-color);
  border: 0;
  display: inline-block;
  font-family: "Noto Sans", sans-serif;
  font-weight: 600;
  line-height: 1;
  text-decoration: underline;
  text-decoration-skip-ink: auto;
  text-decoration-thickness: 0.0625rem;
  text-underline-offset: 0.1875rem;
}
@supports (mask: url("../images/add.svg")) {
  .link--inverse::after,
ul:not(.link-list) a:not([class])::after, a:not([class])::after,
.link--breadcrumb::after,
.link::after {
    background: var(--color);
    content: "";
    display: inline-block;
    height: 1.25em;
    margin-bottom: -0.3125em;
    margin-left: -0.125em;
    mask: url("../images/chevron-right.svg") no-repeat center;
    mask-position: left;
    mask-size: 1.25em;
    position: relative;
    text-decoration: underline;
    width: 1em;
  }
}
.link--inverse:hover,
ul:not(.link-list) a:hover:not([class]), a:hover:not([class]),
.link--breadcrumb:hover,
.link:hover {
  text-decoration-color: var(--hover-underline-color);
  text-decoration-thickness: 0.125rem;
}
@supports (mask: url("../images/add.svg")) {
  .link--inverse:hover::after, a:hover:not([class])::after,
.link--breadcrumb:hover::after,
.link:hover::after {
    background: var(--hover-color);
  }
}
@supports (mask: url("../images/add.svg")) {
  .link--inverse:focus::after, a:focus:not([class])::after,
.link--breadcrumb:focus::after,
.link:focus::after {
    background: var(--focus-color);
  }
}
.link--inverse:active,
ul:not(.link-list) a:active:not([class]), a:active:not([class]),
.link--breadcrumb:active,
.link:active {
  color: var(--active-color);
  text-decoration: none;
}
@supports (mask: url("../images/add.svg")) {
  .link--inverse:active::after, a:active:not([class])::after,
.link--breadcrumb:active::after,
.link:active::after {
    background: var(--active-color);
  }
}
@supports (mask: url("../images/add.svg")) {
  .link--inverse:active:focus::after, a:active:focus:not([class])::after,
.link--breadcrumb:active:focus::after,
.link:active:focus::after {
    background: var(--active-color);
  }
}
@supports (mask: url("../images/add.svg")) {
  [rel=external].link--inverse::after, a[rel=external]:not([class])::after,
[rel=external].link--breadcrumb::after,
[rel=external].link::after {
    margin-bottom: -0.25em;
    margin-left: 0.125rem;
    mask-image: url("../images/external.svg");
    width: 1.25em;
  }
  [href^="#"].link--inverse::after, a[href^="#"]:not([class])::after,
[href^="#"].link--breadcrumb::after,
[href^="#"].link::after {
    margin-left: 0;
    mask-image: url("../images/scroll-down.svg");
    width: 1.25em;
  }
}
.link--inverse:empty::after, a:empty:not([class])::after,
.link--breadcrumb:empty::after,
.link:empty::after {
  display: none;
}

a:not([class]),
.link--breadcrumb,
.link {
  --color: var(--blue-500);
  --hover-color: var(--dark-mint-500);
  --hover-underline-color: var(--blue-300);
  --visited-color: var(--blue-700);
  --active-background-color: var(--blue-500);
  --active-border-color: var(--blue-500);
  --active-color: var(--parent-background-color);
  --focus-background-color: var(--parent-background-color);
  --focus-color: var(--blue-500);
  text-underline-offset: 0.1875rem;
}

ol a:not([class]),
blockquote a:not([class]),
p a:not([class]),
ul:not(.link-list) a:not([class]) {
  --color: var(--blue-500);
  --hover-color: var(--dark-mint-500);
  --hover-underline-color: var(--blue-300);
  --visited-color: var(--blue-700);
  --active-background-color: var(--blue-500);
  --active-border-color: var(--blue-500);
  --active-box-shadow:
  	0 0 0 calc(1.5 * var(--border-width)) var(--parent-background-color),
  	0 0 0 calc(3 * var(--border-width)) var(--active-border-color);
  --active-color: var(--parent-background-color);
  --focus-background-color: var(--parent-background-color);
  --focus-border-color: var(--blue-500);
  --focus-box-shadow:
  	0 0 0 calc(2 * var(--border-width)) var(--parent-background-color),
  	0 0 0 calc(4 * var(--border-width)) var(--focus-border-color);
  --focus-color: var(--blue-500);
}
ol a:not([class]):visited,
blockquote a:not([class]):visited,
p a:not([class]):visited,
ul:not(.link-list) a:not([class]):visited {
  color: var(--visited-color);
}
@supports (mask: url("../images/add.svg")) {
  ol a:not([class]):visited::after,
blockquote a:not([class]):visited::after,
p a:not([class]):visited::after,
ul:not(.link-list) a:not([class]):visited::after {
    background: var(--visited-color);
  }
}
ol a:not([class]):visited:active, ol a:not([class]):visited:active:focus,
blockquote a:not([class]):visited:active,
blockquote a:not([class]):visited:active:focus,
p a:not([class]):visited:active,
p a:not([class]):visited:active:focus,
ul:not(.link-list) a:not([class]):visited:active,
ul:not(.link-list) a:not([class]):visited:active:focus {
  color: var(--active-color);
}
@supports (mask: url("../images/add.svg")) {
  ol a:not([class]):visited:active::after, ol a:not([class]):visited:active:focus::after,
blockquote a:not([class]):visited:active::after,
blockquote a:not([class]):visited:active:focus::after,
p a:not([class]):visited:active::after,
p a:not([class]):visited:active:focus::after,
ul:not(.link-list) a:not([class]):visited:active::after,
ul:not(.link-list) a:not([class]):visited:active:focus::after {
    background: var(--active-color);
  }
}
@supports (mask: url("../images/add.svg")) {
  ol a:not([class]):visited:hover::after,
blockquote a:not([class]):visited:hover::after,
p a:not([class]):visited:hover::after,
ul:not(.link-list) a:not([class]):visited:hover::after {
    background: var(--hover-color);
  }
}
@supports (mask: url("../images/add.svg")) {
  ol a:not([class]):visited:focus::after,
blockquote a:not([class]):visited:focus::after,
p a:not([class]):visited:focus::after,
ul:not(.link-list) a:not([class]):visited:focus::after {
    background: var(--visited-color);
  }
}

.link--inverse {
  --color: var(--off-white);
  --hover-color: var(--white);
  --hover-underline-color: var(--blue-300);
  --visited-color: var(--blue-50);
  --active-background-color: var(--blue-200);
  --active-border-color: var(--blue-200);
  --active-color: var(--parent-background-color);
  --focus-background-color: var(--parent-background-color);
  --focus-border-color: var(--blue-200);
  --focus-color: var(--blue-200);
}

ol a.link--inverse,
blockquote a.link--inverse,
p a.link--inverse,
ul:not(.link-list) a.link--inverse {
  --active-box-shadow:
  	0 0 0 calc(2 * var(--border-width)) var(--parent-background-color),
  	0 0 0 calc(4 * var(--border-width)) var(--active-border-color);
  --focus-background-color: transparent;
  --focus-box-shadow:
  	0 0 0 calc(2 * var(--border-width)) var(--parent-background-color),
  	0 0 0 calc(4 * var(--border-width)) var(--focus-border-color);
  --focus-color: var(--off-white);
}

.link--breadcrumb {
  font-size: 0.875rem;
}

.link-list {
  list-style: none;
  padding-left: 0;
}
.link-list__item {
  margin-top: 0;
}
.link-list a {
  --color: var(--dark-mint-500);
  --border-width: 0.0625rem;
  --active-color: var(--off-white);
  --visited-color: var(--blue-700);
  --focus-box-shadow:
  	0 0 0 calc(2 * var(--border-width)) var(--parent-background-color),
  	0 0 0 calc(4 * var(--border-width)) var(--focus-border-color);
  --active-box-shadow:
  	0 0 0 calc(2 * var(--border-width)) var(--parent-background-color),
  	0 0 0 calc(4 * var(--border-width)) var(--active-border-color);
  background-color: transparent;
  border: 0;
  border-bottom: solid var(--border-width) var(--color);
  display: block;
  font-weight: 400;
  height: 3.375rem;
  line-height: normal;
  padding-bottom: 0.5rem;
  padding-top: 1rem;
  text-decoration: none;
}
@supports (mask: url("../images/add.svg")) {
  .link-list a::after {
    float: right;
    margin-top: 0.125rem;
  }
}
.link-list--inverse a {
  --color: var(--off-white);
  --hover-color: var(--color);
  --hover-underline-color: var(--blue-300);
  --active-background-color: var(--blue-200);
  --active-border-color: var(--blue-200);
  --active-color: var(--parent-background-color);
  --focus-background-color: var(--parent-background-color);
  --focus-border-color: var(--blue-200);
  --focus-color: var(--off-white);
  --outline-color: var(--focus-color);
  --visited-color: var(--blue-50);
}
.link-list a:hover {
  background-color: transparent;
  border-bottom: solid 0.1875rem var(--hover-underline-color);
}
.link-list a:focus {
  background-color: var(--focus-background-color);
  border-bottom: 0;
  margin-left: -1rem;
  margin-right: -1rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.link-list a:active,
.link-list a:active:hover,
.link-list a:active:focus {
  background-color: var(--active-background-color);
  border-bottom: 0;
  color: var(--active-color);
  margin-left: -1rem;
  margin-right: -1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  text-decoration: none;
}

.notification {
  --parent-background-color: var(--white);
  background-color: var(--white);
  border-left: solid 0.25rem var(--blue-400);
  border-radius: 0.1875rem;
  box-shadow: 0 0 0.625rem rgba(0, 0, 0, 0.16);
  font-family: "Noto Serif", sans-serif;
  font-size: 1rem;
  margin-bottom: 1.875rem;
  padding: 1.75rem;
  position: sticky;
  top: 1.875rem;
  width: 100%;
  z-index: 1;
}
.notification button {
  position: absolute;
  right: -0.5rem;
  top: 0.75rem;
}
.notification .notification__title {
  color: var(--blue-400);
  font-weight: 600;
  margin-top: 0;
}
.notification .notification__content {
  margin-top: 0.75rem;
  padding-right: 2rem;
}
.notification a {
  display: inline;
}
.notification a::after {
  display: none;
}

.notification--success {
  border-left-color: var(--green-300);
}
.notification--success .notification__title {
  color: var(--green-500);
}

.notification--warning {
  border-left-color: var(--yellow-500);
}
.notification--warning .notification__title {
  color: var(--dark-mint-500);
}

.notification--error {
  border-left-color: var(--red-300);
}
.notification--error .notification__title {
  color: var(--red-500);
}

.tags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none;
  margin-left: -0.375rem;
  margin-right: -0.375rem;
}

.tag {
  display: block;
  margin-left: 0.375rem;
  margin-right: 0.375rem;
  margin-top: 0.75rem;
}

.tag__link {
  --background-color: var(--blue-50);
  --border-width: 0.0625rem;
  --color: var(--blue-600);
  --outline-color: var(--white);
  --hover-background-color: var(--grey-200);
  --hover-color: var(--blue-500);
  --active-background-color: var(--blue-700);
  --active-border-color: var(--blue-700);
  --active-box-shadow:
  	0 0 0 calc(2 * var(--border-width)) var(--outline-color),
  	0 0 0 calc(4 * var(--border-width)) var(--active-border-color);
  --active-color: var(--white);
  --focus-background-color: var(--grey-200);
  --focus-border-color: var(--blue-500);
  --focus-box-shadow:
  	0 0 0 calc(2 * var(--border-width)) var(--focus-border-color) inset,
  	0 0 0 calc(2 * var(--border-width)) var(--outline-color),
  	0 0 0 calc(4 * var(--border-width)) var(--focus-border-color);
  --focus-color: var(--blue-500);
  border-radius: 0.9375rem;
  display: block;
  font-family: "Noto Sans", sans-serif;
  font-size: 0.875rem;
  font-weight: 600;
  min-height: 1.875rem;
  padding: 0.1875rem 0.5rem;
  text-align: left;
  text-decoration: underline;
  text-decoration-skip-ink: auto;
  text-underline-offset: 0.125rem;
  width: auto;
}
.tag__link:visited {
  color: var(--blue-700);
}
.tag__link:active, .tag__link:focus:active {
  border: solid var(--border-width) var(--blue-400);
}

.badge {
  background: var(--grey-200);
  border: solid 0.0625rem transparent;
  border-radius: 0.9375rem;
  color: var(--dark-mint-500);
  display: inline-block;
  font-family: "Noto Sans", sans-serif;
  font-size: 0.875rem;
  height: auto;
  margin-left: 0.375rem;
  margin-right: 0.375rem;
  margin-top: 0.75rem;
  min-height: 1.875rem;
  padding: 0.1875rem 0.5rem;
  text-align: left;
  text-decoration: none;
  width: auto;
}

.pagination {
  height: auto;
  margin-top: 4rem;
  text-align: center;
  width: calc(((100% - ((8 - 1) * 1.875rem)) * 1) + (7 * 1.875rem));
}
@supports (display: grid) {
  .pagination {
    grid-column: 1/9;
    width: 100%;
  }
}

.pagination .nav-links {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 0;
}
.pagination .nav-links > * + * {
  margin-left: 0.1875rem;
}

.link--pagination {
  --background-color: transparent;
  --color: var(--blue-500);
  --outline-color: var(--white);
  --focus-background-color: var(--off-white);
  --focus-color: var(--blue-500);
  --focus-box-shadow:
  	0 0 0 0.125rem var(--outline-color) inset,
  	0 0 0 0.25rem var(--focus-color) inset;
  --active-box-shadow: none;
  align-items: center;
  border: solid var(--border-width) transparent;
  border-radius: 50%;
  display: inline-flex;
  font-family: "Noto Sans", sans-serif;
  font-weight: 400;
  height: 2.453125rem;
  justify-content: center;
  margin: 0;
  padding: 0.375rem 0;
  text-underline-offset: 0.5rem;
  width: 2.453125rem;
}
.link--pagination::after {
  display: none;
}
.link--pagination:hover {
  font-weight: 700;
  text-decoration-thickness: 0.125rem;
}
.link--pagination:focus {
  border-color: var(--focus-color);
  font-weight: 700;
  text-decoration: none;
}
.link--pagination:active {
  border-color: transparent;
}
.link--pagination svg {
  margin-bottom: 0;
}

.pagination .page {
  align-items: center;
  background-color: var(--background-color, transparent);
  border: solid var(--border-width) transparent;
  border-radius: 50%;
  color: var(--color, var(--dark-mint-500));
  display: inline-flex;
  font-family: "Noto Sans", sans-serif;
  font-weight: 400;
  height: 2.453125rem;
  justify-content: center;
  padding: 0.375rem 0;
  text-underline-offset: 0.5rem;
  width: 2.453125rem;
}
.pagination .page.current {
  --background-color: var(--blue-500);
  --color: var(--white);
}
.pagination .page.dots {
  vertical-align: super;
}

.pagination--inverse .link--pagination {
  --color: var(--off-white);
  --outline-color: var(--dark-mint-500);
  --active-color: var(--off-white);
  --hover-color: var(--white);
  --visited-color: var(--blue-50);
  --focus-background-color: var(--dark-mint-500);
  --focus-color: var(--off-white);
}
.pagination--inverse .page {
  --color: var(--off-white);
}
.pagination--inverse .page.current {
  --background-color: var(--off-white);
  --color: var(--dark-mint-500);
}

@media (min-width: 80rem) {
  .pagination {
    margin-left: calc((100% - 11* 1.875rem) * 4 / 12 + (5 * 1.875rem));
    width: calc(((100% - ((12 - 1) * 1.875rem)) * 0.6666666667) + (7 * 1.875rem));
    margin-top: 4.5rem;
  }
  @supports (display: grid) {
    .pagination {
      margin-left: 0;
    }
  }
  @supports (display: grid) {
    .pagination {
      grid-column: 5/13;
      width: 100%;
    }
  }
}
@media (min-width: 120rem) {
  .pagination {
    margin-left: calc((100% - 15* 1.875rem) * 4 / 16 + (5 * 1.875rem));
    width: calc(((100% - ((16 - 1) * 1.875rem)) * 0.75) + (11 * 1.875rem));
    margin-top: 5rem;
  }
  @supports (display: grid) {
    .pagination {
      margin-left: 0;
    }
  }
  @supports (display: grid) {
    .pagination {
      grid-column: 5/17;
      width: 100%;
    }
  }
}
.disclosure-button svg {
  transition: transform 0.3s;
}
@media (prefers-reduced-motion: reduce) {
  .disclosure-button svg {
    transition: none;
  }
}
.disclosure-button[aria-expanded=true] svg {
  transform: rotate(-180deg);
}

.has-modal {
  overflow: hidden;
  position: fixed;
}

.filter-wrapper,
.sort-wrapper {
  padding: 1.25rem 0 1.5rem;
  position: relative;
}

.filter-wrapper {
  width: 50%;
}

.sort-wrapper {
  text-align: right;
  width: 50%;
}
.sort-wrapper .menu-button .button {
  margin-right: -0.5rem;
}

.save-wrapper {
  margin-top: -1.25rem;
  padding: 0 0 1.5rem;
  position: relative;
}

.accordion + .input-group {
  margin-top: 1.5rem;
}

.filters label {
  font-weight: 400;
}
@media (max-width: 79.9375rem) {
  .filters {
    display: none;
  }
  .filters [id^=deselect] {
    --color: var(--off-white);
    --outline-color: var(--off-white);
    --hover-background-color: var(--blue-600);
    --hover-color: var(--blue-50);
    --active-background-color: var(--blue-600);
    --active-color: var(--blue-200);
    --focus-color: var(--off-white);
    --focus-background-color: transparent;
    --focus-box-shadow: 0 0 0 var(--border-width) var(--dark-mint-500), 0 0 0 calc(2 * var(--border-width)) var(--outline-color);
    --focus-box-shadow: 0 0 0 var(--border-width) var(--outline-color), 0 0 0 calc(2 * var(--border-width)) var(--dark-mint-500), 0 0 0 calc(3 * var(--border-width)) var(--outline-color);
  }
  .filters .button--disc {
    --color: var(--off-white);
    --background-color: var(--blue-700);
    --outline-color: var(--off-white);
    --hover-background-color: var(--black);
    --active-background-color: var(--blue-500);
  }
}

.accordion--filter-list .accordion__content > .input-group li ul {
  margin-top: 0.75rem;
  padding-left: 1.875rem;
}
@media (max-width: 79.9375rem) {
  .accordion--filter-list .accordion__heading {
    --border-width: 0.0625rem;
    --color: var(--off-white);
    border-top-color: var(--dark-mint-500);
    margin-left: -1.875rem;
    margin-right: 0;
    padding-left: 1.875rem;
    padding-right: 1.875rem;
    width: calc(100% + 2 * 1.875rem);
  }
  .accordion--filter-list .accordion__control {
    --border-width: 0.0625rem;
    --color: var(--off-white);
    --hover-color: var(--off-white);
    --hover-underline-color: var(--blue-300);
    --active-color: var(--parent-background-color);
    --active-background-color: var(--blue-200);
    --active-border-color: var(--blue-200);
    --focus-background-color: var(--parent-background-color);
    --focus-border-color: var(--blue-200);
    --focus-color: var(--off-white);
    border-top-color: var(--dark-mint-500);
    height: 4.3125rem;
    margin-left: -1.875rem;
    margin-right: 0;
    padding-left: 1.875rem;
    padding-right: 1.875rem;
    width: calc(100% + 2 * 1.875rem);
    --focus-box-shadow:
    	0 0 0 calc(3 * var(--border-width)) var(--focus-background-color) inset,
    	0 0 0 calc(5 * var(--border-width)) var(--focus-border-color) inset;
    --active-box-shadow:
    	0 0 0 calc(3 * var(--border-width)) var(--parent-background-color) inset,
    	0 0 0 calc(5 * var(--border-width)) var(--active-border-color) inset,
    	0 0 0 calc(7 * var(--border-width)) var(--parent-background-color) inset;
  }
  .accordion--filter-list .accordion__control svg {
    margin-right: 0.4375rem;
  }
  .accordion--filter-list .accordion__control:active, .accordion--filter-list .accordion__control:focus {
    border-top-color: var(--parent-background-color);
  }
  .accordion--filter-list .accordion__control:hover {
    border-top-color: var(--hover-underline-color);
  }
  .accordion--filter-list .accordion__control:hover svg {
    color: inherit;
  }
  .accordion--filter-list .accordion__control:hover:active, .accordion--filter-list .accordion__control:hover:focus {
    border-top-color: var(--parent-background-color);
  }
  .accordion--filter-list .accordion__control[aria-expanded=true]:active {
    box-shadow: var(--active-box-shadow);
  }
  .accordion--filter-list .accordion__control[aria-expanded=true]:active:focus {
    border-top-color: var(--parent-background-color);
    margin-left: -1.875rem;
    margin-right: 0;
    padding-left: 1.875rem;
    padding-right: 1.875rem;
    width: calc(100% + 2 * 1.875rem);
  }
  .accordion--filter-list .accordion__control[aria-expanded=true] + .accordion__content {
    background: var(--blue-600);
    color: var(--white);
    margin-left: -1.875rem;
    margin-right: 0;
    padding-left: 1.875rem;
    padding-right: 1.875rem;
    width: calc(100% + 2 * 1.875rem);
  }
  .accordion--filter-list .accordion__content > .input-group li ul {
    --parent-background-color: var(--dark-mint-500);
    background-color: var(--dark-mint-500);
    margin-bottom: -0.9375rem;
    margin-left: -1.875rem;
    margin-right: -1.875rem;
    margin-top: 0.75rem;
    padding-bottom: 0.375rem;
    padding-left: 2.5rem;
    padding-top: 0.375rem;
    width: calc(100% + 3.75rem);
  }
}
.accordion--filter-list .accordion__content > .input-group {
  margin-bottom: 0;
  margin-top: 0;
}
.accordion--filter-list .accordion__content > .input-group > li {
  padding: 0.9375rem 0 0.8125rem;
  position: relative;
}
.accordion--filter-list .accordion__content > .input-group > li + li {
  border-top: 0.0625rem solid var(--dark-mint-500);
}
.accordion--filter-list .accordion__content > .input-group button {
  position: absolute;
  right: 0;
  top: 0.5625rem;
}
.accordion--filter-list .accordion__content > .input-group li [aria-expanded=false] + ul {
  display: none;
}
.accordion--filter-list .accordion__content > .input-group li + li {
  margin-top: 0;
}
.accordion--filter-list .accordion__content > .input-group ul li {
  padding: 0.75rem 0;
}
.accordion--filter-list .accordion__pane--expanded .accordion__content {
  background: transparent;
  color: var(--dark-mint-500);
}

@media (min-width: 37.5rem) {
  .filter-wrapper {
    width: calc(((100% - ((8 - 1) * 1.875rem)) * 0.5) + (3 * 1.875rem));
  }
  @supports (display: grid) {
    .filter-wrapper {
      grid-column: 1/5;
      width: 100%;
    }
  }

  .sort-wrapper {
    width: calc(((100% - ((8 - 1) * 1.875rem)) * 0.5) + (3 * 1.875rem));
  }
  @supports (display: grid) {
    .sort-wrapper {
      grid-column: 5/9;
      width: 100%;
    }
  }

  .save-wrapper {
    width: calc(((100% - ((8 - 1) * 1.875rem)) * 1) + (7 * 1.875rem));
  }
  @supports (display: grid) {
    .save-wrapper {
      grid-column: 1/9;
      width: 100%;
    }
  }
}
#hide-filters {
  float: right;
  margin-right: -0.5rem;
}

@media (max-width: 79.9375rem) {
  .filters--expanded {
    --parent-background-color: var(--blue-500);
    background: var(--parent-background-color);
    color: var(--white);
    display: block;
    height: 100%;
    left: 0;
    overflow: scroll;
    padding: 1.875rem;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99;
  }
  .filters--expanded h2:focus {
    outline: none;
  }
  .filters--expanded .accordions {
    margin-top: 1.5rem;
  }

  .accordion--filter-list + .input-group {
    background: var(--off-white);
    bottom: 0;
    left: 0;
    padding: 0.75rem 1.875rem;
    position: fixed;
    text-align: center;
    width: 100vw;
  }
  .accordion--filter-list + .input-group [type=submit] {
    display: inline-block;
  }
}
@media (min-width: 37.5rem) and (max-width: 79.9375rem) {
  .sort-wrapper {
    text-align: right;
  }
  .sort-wrapper label,
.sort-wrapper select {
    display: inline;
  }
  .sort-wrapper label {
    margin-right: 0.5rem;
  }
  .sort-wrapper select {
    margin-top: 0;
  }
}
@media (max-width: 79.9375rem) {
  .filters,
.filters .form {
    --text-border-color-default: var(--grey-500);
    --text-border-color-disabled: var(--grey-500);
    --text-border-color-hover: var(--blue-300);
    --text-border-color-focus: var(--off-white);
    --text-border-color-error: var(--red-300);
    --text-border-color-error-hover: var(--red-300);
    --text-border-color-error-focus: var(--red-300);
    --text-value-color-disabled: var(--grey-500);
    --text-value-color-error: var(--red-500);
    --text-background-color-disabled: var(--grey-200);
    --input-group--error-color: var(--red-200);
    --text-focus-box-shadow:
    	0 0 0 calc(1 * var(--border-width)) var(--parent-background-color),
    	0 0 0 calc(3 * var(--border-width)) var(--text-border-color-focus);
    --text-error-focus-box-shadow:
    	0 0 0 calc(1 * var(--border-width)) var(--parent-background-color),
    	0 0 0 calc(3 * var(--border-width)) var(--text-border-color-error-hover);
    --checkbox-radio-hover-color: var(--blue-300);
    --checkbox-radio-active-color: var(--blue-400);
    --checkbox-radio-border-color: var(--off-white);
    --checkbox-radio-checked-background-color: var(--off-white);
    --checkbox-radio-focus-border-color: var(--blue-300);
    --checkbox-radio-focus-hover-background-color: default;
  }
  .filters [role=checkbox][aria-checked=mixed]::before,
.filters .form [role=checkbox][aria-checked=mixed]::before {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 2"><path fill="%23000000" d="M0,1A1,1,0,0,0,1,2H7A1,1,0,0,0,7,0H1A1,1,0,0,0,0,1Z"/></svg>');
  }
  .filters input[type=checkbox]:checked::before,
.filters [role=checkbox][aria-checked=true]::before,
.filters .form input[type=checkbox]:checked::before,
.filters .form [role=checkbox][aria-checked=true]::before {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10.572" height="7.742" viewBox="0 0 10.572 7.742"><path d="M.681 3.812l3.148 2.931L9.864.708" fill="none" stroke="%23000000" stroke-linejoin="round" class="stroke" stroke-width="2"/></svg>');
  }
  .filters input[type=checkbox]:checked:focus::before,
.filters [role=checkbox][aria-checked=true]:focus::before,
.filters .form input[type=checkbox]:checked:focus::before,
.filters .form [role=checkbox][aria-checked=true]:focus::before {
    box-shadow: 0 0 0 calc(2 * var(--border-width)) var(--parent-background-color), 0 0 0 calc(4 * var(--border-width)) var(--checkbox-radio-focus-border-color);
  }
}
@media (min-width: 80rem) {
  .filter-wrapper {
    margin-right: calc((100% - 11* 1.875rem) * 1 / 12 + (2 * 1.875rem));
    padding-top: 4.6875rem;
    width: calc(((100% - ((12 - 1) * 1.875rem)) * 0.25) + (2 * 1.875rem));
  }
  @supports (display: grid) {
    .filter-wrapper {
      margin-right: 0;
    }
  }
  @supports (display: grid) {
    .filter-wrapper {
      grid-row: 2/5;
    }
  }
  .filter-wrapper::before {
    display: none;
  }
  @supports (display: grid) {
    .filter-wrapper {
      margin-right: 0;
    }
  }
  @supports (display: grid) {
    .filter-wrapper {
      grid-column: 1/4;
      width: 100%;
    }
  }

  .sort-wrapper {
    padding: 1.875rem 0 0;
    width: calc(((100% - ((12 - 1) * 1.875rem)) * 0.6666666667) + (7 * 1.875rem));
  }
  @supports (display: grid) {
    .sort-wrapper {
      grid-column: 5/13;
      width: 100%;
    }
  }

  .search-results .sort-wrapper {
    width: calc(((100% - ((12 - 1) * 1.875rem)) * 0.3333333333) + (3 * 1.875rem));
    text-align: left;
  }
  @supports (display: grid) {
    .search-results .sort-wrapper {
      grid-column: 5/9;
      width: 100%;
    }
  }

  .save-wrapper {
    width: calc(((100% - ((12 - 1) * 1.875rem)) * 0.3333333333) + (3 * 1.875rem));
    margin-top: 0;
    padding: 1.875rem 0 0;
    text-align: right;
  }
  @supports (display: grid) {
    .save-wrapper {
      grid-column: 9/13;
      width: 100%;
    }
  }
  .save-wrapper .button--borderless {
    display: inline-block;
    margin: 0.375rem -0.5rem 0.375rem 1.5rem;
  }

  #show-filters,
#hide-filters {
    display: none;
  }

  .filters {
    display: block;
  }
  .filters h2 {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    word-wrap: normal !important;
  }
  .filters h2 + * {
    margin-top: 0;
  }
  .filters #apply-filters {
    display: block;
  }

  .accordion--filter-list {
    display: block;
    margin-top: 0;
  }

  .filter,
.sort {
    display: block;
  }
}
@media (min-width: 120rem) {
  .filter-wrapper {
    margin-right: calc((100% - 15* 1.875rem) * 1 / 16 + (2 * 1.875rem));
    width: calc(((100% - ((16 - 1) * 1.875rem)) * 0.1875) + (2 * 1.875rem));
  }
  @supports (display: grid) {
    .filter-wrapper {
      margin-right: 0;
    }
  }
  @supports (display: grid) {
    .filter-wrapper {
      grid-column: 1/4;
      width: 100%;
    }
  }

  .sort-wrapper {
    width: calc(((100% - ((16 - 1) * 1.875rem)) * 0.75) + (11 * 1.875rem));
  }
  @supports (display: grid) {
    .sort-wrapper {
      grid-column: 5/17;
      width: 100%;
    }
  }

  .search-results .sort-wrapper {
    width: calc(((100% - ((16 - 1) * 1.875rem)) * 0.375) + (5 * 1.875rem));
    text-align: left;
  }
  @supports (display: grid) {
    .search-results .sort-wrapper {
      grid-column: 5/11;
      width: 100%;
    }
  }

  .save-wrapper {
    width: calc(((100% - ((16 - 1) * 1.875rem)) * 0.375) + (5 * 1.875rem));
  }
  @supports (display: grid) {
    .save-wrapper {
      grid-column: 11/17;
      width: 100%;
    }
  }
}
.resource-list {
  width: calc(((100% - ((8 - 1) * 1.875rem)) * 1) + (7 * 1.875rem));
}
@supports (display: grid) {
  .resource-list {
    grid-column: 1/9;
    width: 100%;
  }
}

.cards {
  list-style: none;
  margin-top: 1.875rem;
}

.card {
  --parent-background-color: var(--white);
  background-color: var(--white);
  border-top: 0.1875rem solid var(--blue-500);
  box-shadow: 0 0 0.3125rem rgba(0, 0, 0, 0.16);
  height: 100%;
  padding: 1.375rem;
  position: relative;
  transition: box-shadow 0.1s;
  width: 100%;
}
@media (prefers-reduced-motion: reduce) {
  .card {
    transition: none;
  }
}
.card:hover {
  box-shadow: 0 0 1.25rem rgba(0, 0, 0, 0.25);
}
.card:focus-within {
  border: solid 0.1875rem var(--blue-500);
  border-radius: 0.125rem;
  box-shadow: 0 0 1.5625rem rgba(22, 96, 93, 0.25);
  padding: 1.375rem 1.1875rem 1.1875rem;
}

.cards .card__wrapper + .card__wrapper {
  margin-top: 1.875rem;
}

.card__image {
  border-left: solid 0.1875rem transparent;
  border-right: solid 0.1875rem transparent;
  margin-bottom: 0;
  margin-left: -1.375rem;
  margin-right: -1.375rem;
  margin-top: -1.375rem;
  padding: 0;
}
.card__image img {
  display: block;
  height: auto;
  margin: 0 -0.1875rem;
  width: calc(100% + 0.375rem);
}

.card:focus-within .card__image {
  overflow: hidden;
}

.card__image + * {
  margin-top: 1.375rem;
}

.card header {
  position: relative;
}

.card__title {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.5;
  margin-top: 0;
}

.card__link {
  color: var(--dark-mint-500);
  line-height: inherit;
  margin-left: -1.375rem;
  margin-right: -1.375rem;
  min-width: calc(100% + 2.75rem);
  padding-bottom: 0.125rem;
  padding-left: 1.375rem;
  padding-right: 1.375rem;
  text-decoration: none;
}
.card__link:visited {
  color: var(--dark-mint-500);
}
.card__link:focus {
  outline: none;
}
.card__link[rel=external]::after {
  background-color: var(--dark-mint-500);
}
.card__link:not([rel=external])::after {
  content: "";
  display: none;
}

.card header + * {
  margin-top: 0.5625rem;
}

.card__byline,
.card__meta,
.card__description {
  margin-top: 0.5625rem;
}

.card__byline,
.card__meta,
.card__description,
.card__favorite {
  color: var(--dark-mint-500);
  font-size: 0.875rem;
}

.card__byline svg,
.card__meta svg {
  margin-bottom: -0.25em;
}

.card__meta {
  padding-left: 1.5em;
  text-indent: -1.5em;
}

.card__format {
  background: var(--blue-500);
  color: var(--white);
  display: inline-block;
  font-family: "Noto Sans", sans-serif;
  font-size: 0.875rem;
  font-weight: 600;
  margin-bottom: 0.75rem;
  padding: 0.125rem 0.375rem;
}

.card__tags {
  display: inline-flex;
  margin-top: 0.375rem;
}
.card__tags .overflow {
  color: var(--grey-600);
  font-size: 0.875rem;
  margin-left: 0.25rem;
  margin-top: 0.75rem;
  padding-top: 0.1875rem;
}

.card__tags + .card__meta {
  margin-top: 0.875rem;
}

.card__added {
  color: var(--grey-600);
  font-style: italic;
}

.card__favorite {
  color: var(--dark-mint-500);
  display: inline-block;
  float: right;
  font-weight: 700;
}
.card__favorite svg {
  color: var(--red-500);
}

.card__meta.card__subscription {
  color: var(--red-500);
}
.card__meta.card__subscription svg {
  color: inherit;
}

.card--resource {
  border-top-color: #ff621a;
}
.card--resource:focus-within {
  border-color: #ff621a;
  box-shadow: 0 0 1.5625rem rgba(255, 98, 26, 0.25);
}
.card--resource .card__title a:focus {
  background: #ff621a;
  color: var(--dark-mint-500);
}
.card--resource .card__title a:focus::after {
  background-color: var(--dark-mint-500);
}
.card--resource:focus-within .card__title a:focus {
  background: unset;
  color: var(--dark-mint-500);
}
.card--resource .card__format {
  background: unset;
  color: var(--dark-mint-500);
}
.card--resource .card__format {
  color: inherit;
  display: inline;
  font-family: inherit;
  font-weight: inherit;
  padding: 0;
}

.card--project {
  border-top-color: #ff621a;
}
.card--project:focus-within {
  border-color: #ff621a;
  box-shadow: 0 0 1.5625rem rgba(255, 98, 26, 0.25);
}
.card--project .card__title a:focus {
  background: #ff621a;
  color: var(--dark-mint-500);
}
.card--project .card__title a:focus::after {
  background-color: var(--dark-mint-500);
}
.card--project:focus-within .card__title a:focus {
  background: unset;
  color: var(--dark-mint-500);
}
.card--project .card__format {
  background: #ff621a;
  color: var(--dark-mint-500);
}

.card--blog {
  border-top-color: #ffa47a;
}
.card--blog:focus-within {
  border-color: #ffa47a;
  box-shadow: 0 0 1.5625rem rgba(255, 164, 122, 0.25);
}
.card--blog .card__title a:focus {
  background: #ffa47a;
  color: var(--dark-mint-500);
}
.card--blog .card__title a:focus::after {
  background-color: var(--dark-mint-500);
}
.card--blog:focus-within .card__title a:focus {
  background: unset;
  color: var(--dark-mint-500);
}
.card--blog .card__format {
  background: #ffa47a;
  color: var(--dark-mint-500);
}

.card--person {
  border-top-color: #16605d;
}
.card--person:focus-within {
  border-color: #16605d;
  box-shadow: 0 0 1.5625rem rgba(22, 96, 93, 0.25);
}
.card--person .card__title a:focus {
  background: #16605d;
  color: var(--white);
}
.card--person .card__title a:focus::after {
  background-color: var(--white);
}
.card--person:focus-within .card__title a:focus {
  background: unset;
  color: var(--dark-mint-500);
}
.card--person .card__format {
  background: #16605d;
  color: var(--white);
}

.card--event {
  border-top-color: #30cfc9;
}
.card--event:focus-within {
  border-color: #30cfc9;
  box-shadow: 0 0 1.5625rem rgba(48, 207, 201, 0.25);
}
.card--event .card__title a:focus {
  background: #30cfc9;
  color: var(--dark-mint-500);
}
.card--event .card__title a:focus::after {
  background-color: var(--dark-mint-500);
}
.card--event:focus-within .card__title a:focus {
  background: unset;
  color: var(--dark-mint-500);
}
.card--event .card__format {
  background: #30cfc9;
  color: var(--dark-mint-500);
}

.card--platformcoop {
  border-top-color: #45d385;
}
.card--platformcoop:focus-within {
  border-color: #45d385;
  box-shadow: 0 0 1.5625rem rgba(69, 211, 133, 0.25);
}
.card--platformcoop .card__title a:focus {
  background: #45d385;
  color: var(--dark-mint-500);
}
.card--platformcoop .card__title a:focus::after {
  background-color: var(--dark-mint-500);
}
.card--platformcoop:focus-within .card__title a:focus {
  background: unset;
  color: var(--dark-mint-500);
}
.card--platformcoop .card__format {
  background: #45d385;
  color: var(--dark-mint-500);
}

.card--story {
  border-top-color: #face00;
}
.card--story:focus-within {
  border-color: #face00;
  box-shadow: 0 0 1.5625rem rgba(250, 206, 0, 0.25);
}
.card--story .card__title a:focus {
  background: #face00;
  color: var(--dark-mint-500);
}
.card--story .card__title a:focus::after {
  background-color: var(--dark-mint-500);
}
.card--story:focus-within .card__title a:focus {
  background: unset;
  color: var(--dark-mint-500);
}
.card--story .card__format {
  background: #face00;
  color: var(--dark-mint-500);
}

@media (min-width: 37.5rem) {
  .cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  @supports (display: grid) {
    .cards {
      display: grid;
      gap: 1.875rem;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .cards .card__wrapper {
    margin-bottom: 1.875rem;
    width: calc(50% - 1.875rem / 2);
  }
  @supports (display: grid) {
    .cards .card__wrapper {
      margin-bottom: 0;
      width: 100%;
    }
  }

  .card:nth-child(even) {
    margin-left: 1.875rem;
  }
  @supports (display: grid) {
    .card:nth-child(even) {
      margin-left: 0;
    }
  }

  .cards .card__wrapper + .card__wrapper {
    margin-top: 0;
  }
}
@media (min-width: 80rem) {
  .resource-list {
    width: calc(((100% - ((12 - 1) * 1.875rem)) * 0.6666666667) + (7 * 1.875rem));
  }
  @supports (display: grid) {
    .resource-list {
      grid-column: 5/13;
      width: 100%;
    }
  }

  @supports (display: grid) {
    .cards {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
@media (min-width: 120rem) {
  .resource-list {
    width: calc(((100% - ((16 - 1) * 1.875rem)) * 0.75) + (11 * 1.875rem));
  }
  @supports (display: grid) {
    .resource-list {
      grid-column: 5/17;
      width: 100%;
    }
  }

  @supports (display: grid) {
    .cards {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .cards .card__wrapper {
    margin-bottom: 1.875rem;
    margin-left: 1.875rem;
    width: calc((100% - (1.875rem * 2)) / 3);
  }
  @supports (display: grid) {
    .cards .card__wrapper {
      margin-bottom: 0;
      margin-left: 0;
      width: 100%;
    }
  }

  .card:nth-child(even) {
    margin-left: 1.875rem;
  }
  @supports (display: grid) {
    .card:nth-child(even) {
      margin-left: 0;
    }
  }

  .card:nth-child(3n+1) {
    margin-left: 0;
  }
}
.button--invoke-dialog + * {
  display: none;
}

[role=dialog] {
  background-color: #fff;
  border-radius: 0.1875rem;
  box-shadow: 0 0.1875rem 0.375rem rgba(0, 0, 0, 0.16);
  height: auto;
  left: 1.875rem;
  padding: 1.875rem;
  position: fixed;
  top: 20vh;
  width: calc(100% - 2 * 1.875rem);
}
[role=dialog] .buttons {
  margin-top: 1rem;
}
[role=dialog] .buttons button {
  max-width: 100%;
  width: 100%;
}
[role=dialog] .buttons button + button {
  margin-top: 1rem;
}
[role=dialog] p + .input-group {
  margin-top: 1.5rem;
}
[role=dialog] input {
  max-width: 100%;
}

.overlay {
  background: #000;
  height: 100%;
  left: 0;
  opacity: 0.7;
  position: fixed;
  top: 0;
  width: 100%;
}

@media (min-width: 60rem) {
  [role=dialog] {
    left: calc((100% - 32rem) / 2);
    padding: 4.25rem 5.75rem;
    width: 32rem;
  }
  [role=dialog] .buttons {
    margin-top: 1.875rem;
  }
}
.info-cards {
  margin: 4rem -1.875rem;
  width: 100vw;
}

.info-card {
  --parent-background-color: var(--grey-200);
  background-color: var(--grey-200);
  padding: 1.75rem;
  position: relative;
}
.info-card button {
  position: absolute;
  right: -0.5rem;
  top: 0.75rem;
}
.info-card .info-card__title {
  font-family: "Noto Sans", sans-serif;
  font-size: 1.375rem;
  font-weight: 700;
  margin-top: 0;
}
.info-card .info-card__title svg {
  display: block;
  height: 2.5rem;
  margin-bottom: 0.375rem;
  width: 2.5rem;
}
.info-card .info-card__description {
  margin-top: 0.25rem;
}
.info-card .info-card__action {
  margin-top: 1rem;
}

.info-card + .info-card {
  border-top: solid 0.0625rem var(--grey-400);
}

@media (min-width: 37.5rem) {
  .info-cards {
    display: grid;
    grid-column-gap: 1.875rem;
    grid-template-columns: repeat(2, 1fr);
    margin: 6.25rem 0 0;
    width: 100%;
  }

  .info-card,
.info-card + .info-card {
    border-top: solid 0.1875rem var(--blue-500);
  }
}
@media (min-width: 80rem) {
  .info-cards {
    display: block;
    margin-top: 1.875rem;
  }

  .info-card + .info-card {
    margin-top: 1.875rem;
  }
}
[role=banner] .nav {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 5rem;
  margin: 0 0 0 1rem;
  position: relative;
}

.menu-toggle {
  margin-right: 1rem;
  position: relative;
  transition: margin-right 0s;
  z-index: 3;
}

.menu {
  background: var(--dark-mint-400);
  display: block;
  list-style: none;
  margin: 0;
  padding-left: 0;
  padding-top: 5rem;
  position: absolute;
  right: 0;
  top: 0;
  width: 100vw;
  z-index: 2;
}
.menu li {
  border-top: solid 0.0625rem var(--dark-mint-500);
  margin: 0;
}

.menu button svg {
  transition: transform 0.3s;
}
@media (prefers-reduced-motion: reduce) {
  .menu button svg {
    transition: none;
  }
}

[aria-expanded=false] .icon--close {
  display: none;
}

[aria-expanded=false] + * {
  display: none;
}

[aria-expanded=true] .icon--open {
  display: none;
}

[aria-expanded=true] .menu-toggle__label {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important;
}

.menu-toggle[aria-expanded=true] {
  --color: var(--off-white);
  --outline-color: var(--off-white);
  --hover-background-color: var(--blue-600);
  --hover-color: var(--blue-50);
  --active-background-color: var(--blue-600);
  --active-color: var(--blue-200);
  --focus-color: var(--off-white);
  --focus-background-color: transparent;
  --focus-box-shadow: 0 0 0 var(--border-width) var(--outline-color), 0 0 0 calc(2 * var(--border-width)) var(--dark-mint-500), 0 0 0 calc(3 * var(--border-width)) var(--outline-color);
  margin-right: 0.625rem;
}
.menu-toggle[aria-expanded=true] * + svg {
  margin-left: 0;
}
.menu-toggle[aria-expanded=true] svg + * {
  margin-right: 0;
}

.menu__item {
  --background-color: var(--dark-mint-500);
  --outline-color: var(--dark-mint-500);
  --color: var(--off-white);
  --hover-color: var(--dark-mint-500);
  --hover-background-color: var(--white);
  --active-color: var(--hover-color);
  --active-background-color: var(--hover-background-color);
  --focus-color: var(--hover-color);
  --focus-background-color: var(--hover-background-color);
  --focus-box-shadow: 0 0 0 0.1875rem var(--outline-color) inset;
  align-items: center;
  border-left: 0;
  border-left: solid 0.1875rem transparent;
  border-radius: 0;
  display: flex;
  flex-direction: row;
  font-family: "Noto Sans", sans-serif;
  font-size: 1rem;
  font-weight: 700;
  justify-content: space-between;
  line-height: 1.75;
  margin: 0;
  max-width: 100%;
  padding: 1rem 1rem 0.875rem;
  position: relative;
  text-align: left;
  text-decoration: none;
  width: 100%;
}
.menu__item:hover, .menu__item:active {
  border-left-color: transparent;
}
.menu__item:focus {
  border: solid 0.1875rem transparent;
  padding: 0.8125rem 0.8125rem 0.6875rem 1rem;
}
.menu__item[aria-current=page] {
  --background-color: var(--dark-mint-500);
  --hover-background-color: var(--white);
  border-left-color: var(--red-400);
}
.menu__item[aria-current=page]:hover, .menu__item[aria-current=page]:focus {
  border-left-color: transparent;
}
.menu__item[aria-expanded=true] svg {
  transform: rotate(180deg);
}
.menu__item[aria-expanded=true] svg.icon--language {
  transform: none;
}

a.menu__item:hover,
a.menu__item:focus,
a.menu__item:active {
  text-decoration: underline;
  text-decoration-skip-ink: auto;
  text-underline-offset: 0.125rem;
}

.menu-item--languages .menu__label {
  display: block;
}

.menu-item--languages .icon--language {
  display: none;
}

.menu-item--languages [aria-current] {
  display: block;
}

.menu-item--languages [aria-current]::before {
  background-color: transparent;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10.572" height="7.742" viewBox="0 0 10.572 7.742"><path d="M.681 3.812l3.148 2.931L9.864.708" fill="none" stroke="%23fff" stroke-linejoin="round" class="stroke" stroke-width="2"/></svg>');
  background-position: center;
  background-repeat: no-repeat;
  content: "";
  display: inline-block;
  height: 1em;
  margin-right: 0.375rem;
  width: 1em;
}

.menu-item--languages [aria-current]:hover::before,
.menu-item--languages [aria-current]:focus::before {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10.572" height="7.742" viewBox="0 0 10.572 7.742"><path d="M.681 3.812l3.148 2.931L9.864.708" fill="none" stroke="%23203131" stroke-linejoin="round" class="stroke" stroke-width="2"/></svg>');
}

.menu-item--languages [aria-current]:active::before,
.menu-item--languages [aria-current]:active:focus::before {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10.572" height="7.742" viewBox="0 0 10.572 7.742"><path d="M.681 3.812l3.148 2.931L9.864.708" fill="none" stroke="%231d7c79" stroke-linejoin="round" class="stroke" stroke-width="2"/></svg>');
}

.menu__submenu {
  border-top: solid 0.0625rem var(--dark-mint-500);
  list-style: none;
  margin-top: 0;
}
.menu__submenu li:first-child {
  border-top: 0;
}

.menu__submenu .menu__item {
  font-weight: 400;
  padding-left: 2.5rem;
}

.menu__submenu-wrapper.current_page_item > .menu__item,
.menu__submenu-wrapper.current_page_ancestor > .menu__item,
.menu__submenu-wrapper.current-menu-ancestor > .menu__item {
  --background-color: var(--dark-mint-500);
  --hover-background-color: var(--white);
  border-left-color: var(--red-400);
}
.menu__submenu-wrapper.current_page_item > .menu__item:hover, .menu__submenu-wrapper.current_page_item > .menu__item:focus,
.menu__submenu-wrapper.current_page_ancestor > .menu__item:hover,
.menu__submenu-wrapper.current_page_ancestor > .menu__item:focus,
.menu__submenu-wrapper.current-menu-ancestor > .menu__item:hover,
.menu__submenu-wrapper.current-menu-ancestor > .menu__item:focus {
  border-left-color: transparent;
}

@media (min-width: 60rem) {
  [role=banner] .nav {
    height: 5rem;
    margin-left: auto;
  }

  .menu-toggle {
    display: none;
  }

  .menu {
    align-items: flex-end;
    background-color: var(--white);
    display: flex;
    flex-direction: row;
    float: right;
    height: 5rem;
    padding-top: 0;
    position: relative;
    width: auto;
  }
  .menu li {
    border-top: 0;
  }

  [aria-expanded=false] + .menu {
    display: flex;
  }

  .menu__item {
    --background-color: transparent;
    --border-width: 0.125rem;
    --outline-color: var(--off-white);
    --hover-color: var(--off-white);
    --hover-background-color: var(--blue-500);
    --color: var(--blue-500);
    --active-color: var(--off-white);
    --active-background-color: var(--blue-500);
    --focus-background-color: var(--blue-500);
    --focus-color: var(--off-white);
    --focus-box-shadow:
    	0 0 0 var(--border-width) var(--focus-background-color) inset,
    	0 0 0 calc(var(--border-width) * 2) var(--outline-color) inset;
    --active-box-shadow: var(--focus-box-shadow);
    align-items: center;
    border: 0;
    display: flex;
    flex-direction: row;
    font-size: 1rem;
    font-weight: 400;
    height: 5rem;
    justify-content: center;
    margin: 0;
    padding: 0 1rem;
  }
  .menu__item::after {
    background-color: transparent;
    bottom: 0;
    content: "";
    height: 0.1875rem;
    left: 0;
    margin-left: 1rem;
    position: absolute;
    width: calc(100% - 2rem);
  }
  .menu__item:focus, .menu__item:active {
    border: 0;
    border-left: 0;
    box-shadow: var(--focus-box-shadow);
    padding: 0 1rem;
  }
  .menu__item:focus::after, .menu__item:active::after {
    background-color: transparent;
  }
  .menu__item svg {
    margin-bottom: 0;
    margin-left: 0.25rem;
  }
  .menu__item[aria-current=page] {
    --color: var(--dark-mint-500);
    --background-color: transparent;
    --hover-color: var(--off-white);
    --hover-background-color: var(--blue-500);
    --focus-background-color: var(--blue-500);
    --focus-color: var(--off-white);
  }
  .menu__item[aria-current=page]::after {
    background-color: var(--red-400);
  }
  .menu__item[aria-current=page]:hover::after {
    background-color: transparent;
  }
  .menu__item[aria-current=page]:focus {
    border-left-color: transparent;
  }
  .menu__item[aria-current=page]:focus::after {
    background-color: transparent;
  }

  .menu-item--languages .menu__label {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    word-wrap: normal !important;
  }

  .menu-item--languages .icon--language {
    display: block;
    margin-left: 0;
  }

  .menu-item--languages [aria-current]::before {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10.572" height="7.742" viewBox="0 0 10.572 7.742"><path d="M.681 3.812l3.148 2.931L9.864.708" fill="none" stroke="%2316605d" stroke-linejoin="round" class="stroke" stroke-width="2"/></svg>');
  }

  .menu-item--languages [aria-current]:hover::before,
.menu-item--languages [aria-current]:focus::before {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10.572" height="7.742" viewBox="0 0 10.572 7.742"><path d="M.681 3.812l3.148 2.931L9.864.708" fill="none" stroke="%23f9f9f7" stroke-linejoin="round" class="stroke" stroke-width="2"/></svg>');
  }

  .menu-item--languages [aria-current]:active::before,
.menu-item--languages [aria-current]:active:focus::before {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10.572" height="7.742" viewBox="0 0 10.572 7.742"><path d="M.681 3.812l3.148 2.931L9.864.708" fill="none" stroke="%23f9f9f7" stroke-linejoin="round" class="stroke" stroke-width="2"/></svg>');
  }

  .menu__submenu {
    border: solid 1px var(--white);
    border-bottom-left-radius: 0.1875rem;
    border-bottom-right-radius: 0.1875rem;
    box-shadow: 0 0.1875rem 0.375rem rgba(0, 0, 0, 0.16);
    min-width: 15.625rem;
    position: absolute;
  }
  .menu__submenu .menu__item {
    --background-color: var(--white);
    display: flex;
    height: 3.125rem;
    justify-content: flex-start;
    padding-left: 1rem;
  }
  .menu__submenu .menu__item::after {
    display: none;
  }
  .menu__submenu .menu__item[aria-current=page] {
    --hover-color: var(--off-white);
    --hover-background-color: var(--blue-500);
    border-left: solid 0.1875rem var(--red-400);
    padding-left: 0.8125rem;
  }
  .menu__submenu .menu__item[aria-current=page]:focus, .menu__submenu .menu__item[aria-current=page]:hover, .menu__submenu .menu__item[aria-current=page]:active {
    border-left: 0;
    padding-left: 1rem;
  }

  .menu__submenu-wrapper.current_page_item > .menu__item,
.menu__submenu-wrapper.current_page_ancestor > .menu__item,
.menu__submenu-wrapper.current-menu-ancestor > .menu__item {
    --background-color: transparent;
    --hover-color: var(--off-white);
    --hover-background-color: var(--blue-500);
    --color: var(--dark-mint-500);
    --active-color: var(--off-white);
    --active-background-color: var(--blue-500);
    --focus-background-color: var(--blue-500);
    --focus-color: var(--off-white);
  }
  .menu__submenu-wrapper.current_page_item > .menu__item::after,
.menu__submenu-wrapper.current_page_ancestor > .menu__item::after,
.menu__submenu-wrapper.current-menu-ancestor > .menu__item::after {
    background-color: var(--red-400);
  }
  .menu__submenu-wrapper.current_page_item > .menu__item:hover::after, .menu__submenu-wrapper.current_page_item > .menu__item:active::after, .menu__submenu-wrapper.current_page_item > .menu__item:focus::after,
.menu__submenu-wrapper.current_page_ancestor > .menu__item:hover::after,
.menu__submenu-wrapper.current_page_ancestor > .menu__item:active::after,
.menu__submenu-wrapper.current_page_ancestor > .menu__item:focus::after,
.menu__submenu-wrapper.current-menu-ancestor > .menu__item:hover::after,
.menu__submenu-wrapper.current-menu-ancestor > .menu__item:active::after,
.menu__submenu-wrapper.current-menu-ancestor > .menu__item:focus::after {
    background-color: transparent;
  }
}
.home .menu-toggle {
  --color: var(--off-white);
  --outline-color: var(--off-white);
  --hover-background-color: var(--blue-600);
  --hover-color: var(--blue-50);
  --active-background-color: var(--blue-600);
  --active-color: var(--blue-200);
  --focus-color: var(--off-white);
  --focus-background-color: transparent;
  --focus-box-shadow: 0 0 0 var(--border-width) var(--dark-mint-500), 0 0 0 calc(2 * var(--border-width)) var(--outline-color);
  --focus-box-shadow: 0 0 0 var(--border-width) var(--outline-color), 0 0 0 calc(2 * var(--border-width)) var(--parent-background-color), 0 0 0 calc(3 * var(--border-width)) var(--outline-color);
}

@media (min-width: 60rem) {
  .home .menu {
    --parent-background-color: var(--blue-500);
    background-color: var(--blue-500);
  }

  .home .menu .menu__item {
    --color: var(--off-white);
    --outline-color: var(--blue-500);
    --hover-color: var(--blue-500);
    --hover-background-color: var(--off-white);
    --active-color: var(--blue-500);
    --active-background-color: var(--off-white);
    --focus-color: var(--blue-500);
    --focus-background-color: var(--off-white);
  }
  .home .menu .menu__item[aria-current=page]:hover::after, .home .menu .menu__item[aria-current=page]:focus::after {
    background: transparent;
  }

  .home .menu .menu-item--languages [aria-current]::before {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10.572" height="7.742" viewBox="0 0 10.572 7.742"><path d="M.681 3.812l3.148 2.931L9.864.708" fill="none" stroke="%2316605d" stroke-linejoin="round" class="stroke" stroke-width="2"/></svg>');
  }

  .home .menu .menu-item--languages [aria-current]:hover::before,
.home .menu .menu-item--languages [aria-current]:focus::before {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10.572" height="7.742" viewBox="0 0 10.572 7.742"><path d="M.681 3.812l3.148 2.931L9.864.708" fill="none" stroke="%23f9f9f7" stroke-linejoin="round" class="stroke" stroke-width="2"/></svg>');
  }

  .home .menu .menu-item--languages [aria-current]:active::before,
.home .menu .menu-item--languages [aria-current]:active:focus::before {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10.572" height="7.742" viewBox="0 0 10.572 7.742"><path d="M.681 3.812l3.148 2.931L9.864.708" fill="none" stroke="%23f9f9f7" stroke-linejoin="round" class="stroke" stroke-width="2"/></svg>');
  }

  .home .menu .menu__submenu {
    background-color: var(--white);
  }
  .home .menu .menu__submenu .menu__item {
    --background-color: transparent;
    --border-width: 0.125rem;
    --outline-color: var(--off-white);
    --hover-color: var(--off-white);
    --hover-background-color: var(--blue-500);
    --color: var(--blue-500);
    --active-color: var(--off-white);
    --active-background-color: var(--blue-500);
    --focus-background-color: var(--blue-500);
    --focus-color: var(--off-white);
    --focus-box-shadow:
    	0 0 0 var(--border-width) var(--focus-background-color) inset,
    	0 0 0 calc(var(--border-width) * 2) var(--outline-color) inset;
  }
}
.menu-button {
  position: relative;
}

.menu-button button {
  display: inline-block;
}

.menu-button--inverse button {
  --color: var(--off-white);
  --outline-color: var(--off-white);
  --hover-background-color: var(--blue-600);
  --hover-color: var(--blue-50);
  --active-background-color: var(--blue-600);
  --active-color: var(--blue-200);
  --focus-color: var(--off-white);
  --focus-background-color: transparent;
  --focus-box-shadow: 0 0 0 var(--border-width) var(--outline-color), 0 0 0 calc(2 * var(--border-width)) var(--parent-background-color), 0 0 0 calc(3 * var(--border-width)) var(--outline-color);
}

.menu-button__menu {
  z-index: 1;
}
.menu-button__menu [data-popper-arrow],
.menu-button__menu [data-popper-arrow]::before {
  position: absolute;
}
.menu-button__menu [data-popper-arrow]::before {
  background: var(--white);
  content: "";
  height: 1.25rem;
  width: 1.25rem;
}
.menu-button__menu[data-popper-placement^=bottom] [data-popper-arrow], .menu-button__menu[data-popper-placement^=top] [data-popper-arrow] {
  height: 1.25rem;
  overflow: hidden;
  width: 2.5rem;
}
.menu-button__menu[data-popper-placement^=bottom] [data-popper-arrow]::before, .menu-button__menu[data-popper-placement^=top] [data-popper-arrow]::before {
  box-shadow: 0.125rem 0.125rem 0.375rem rgba(0, 0, 0, 0.19);
  left: 50%;
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
}
.menu-button__menu[data-popper-placement^=bottom] {
  margin-top: 1.25rem;
}
.menu-button__menu[data-popper-placement^=bottom] [data-popper-arrow] {
  top: -1.25rem;
}
.menu-button__menu[data-popper-placement^=bottom] [data-popper-arrow]::before {
  top: 1.25rem;
}
.menu-button__menu[data-popper-placement^=top] {
  margin-bottom: 1.25rem;
}
.menu-button__menu[data-popper-placement^=top] [data-popper-arrow] {
  bottom: -1.25rem;
}
.menu-button__menu[data-popper-placement^=left] [data-popper-arrow], .menu-button__menu[data-popper-placement^=right] [data-popper-arrow] {
  height: 2.5rem;
  width: 1.25rem;
}
.menu-button__menu[data-popper-placement^=left] [data-popper-arrow]::before, .menu-button__menu[data-popper-placement^=right] [data-popper-arrow]::before {
  top: 50%;
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
}
.menu-button__menu[data-popper-placement^=left] {
  margin-right: 1.25rem;
}
.menu-button__menu[data-popper-placement^=left] [data-popper-arrow] {
  right: -1.25rem;
}
.menu-button__menu[data-popper-placement^=left] [data-popper-arrow]::before {
  right: 0;
}
.menu-button__menu[data-popper-placement^=right] {
  margin-left: 1.25rem;
}
.menu-button__menu[data-popper-placement^=right] [data-popper-arrow] {
  left: -1.25rem;
}
.menu-button__menu[data-popper-placement^=right] [data-popper-arrow]::before {
  left: 1.25rem;
}

.menu-button__menu ul {
  --parent-background-color: var(--white);
  background-color: var(--white);
  border-radius: 0.1875rem;
  box-shadow: 0 0.1875rem 0.625rem rgba(0, 0, 0, 0.19);
  list-style: none;
  margin-top: 0;
  text-align: left;
}

.menu-button__menu li {
  margin: 0;
}

.menu-button__menu li + li {
  border-top: solid 0.0625rem var(--grey-400);
}

.menu-button__menu li:focus-within + li,
.menu-button__menu li + li:focus-within {
  border-top-color: transparent;
}

.menu-button__menu a {
  --color: var(--blue-500);
  --hover-color: var(--dark-mint-500);
  --focus-box-shadow:
  	0 0 0 var(--border-width) var(--focus-background-color) inset,
  	0 0 0 calc(2 * var(--border-width)) var(--outline-color) inset;
  --active-box-shadow: var(--focus-box-shadow);
  align-items: center;
  display: flex;
  flex-direction: row;
  font-weight: 400;
  justify-content: flex-start;
  line-height: 1.5;
  padding: 0.625rem 0.875rem;
  text-decoration: none;
  width: 100%;
}
.menu-button__menu a::after {
  display: none;
}
.menu-button__menu a:active {
  text-decoration: none;
}
.menu-button__menu a:focus {
  text-decoration: none;
}
.menu-button__menu a:active:focus {
  text-decoration: none;
}
.menu-button__menu a:visited:focus {
  color: var(--white);
}
.menu-button__menu a:visited:active, .menu-button__menu a:visited:active:focus {
  color: var(--blue-400);
}
.menu-button__menu a[aria-current]::before {
  background-color: transparent;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10.572" height="7.742" viewBox="0 0 10.572 7.742"><path d="M.681 3.812l3.148 2.931L9.864.708" fill="none" stroke="%23203131" stroke-linejoin="round" class="stroke" stroke-width="2"/></svg>');
  background-position: center;
  background-repeat: no-repeat;
  content: "";
  display: inline-block;
  height: 1em;
  margin-right: 0.375rem;
  width: 1em;
}
.menu-button__menu a[aria-current]:focus::before {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10.572" height="7.742" viewBox="0 0 10.572 7.742"><path d="M.681 3.812l3.148 2.931L9.864.708" fill="none" stroke="%23fff" stroke-linejoin="round" class="stroke" stroke-width="2"/></svg>');
}
.menu-button__menu a[aria-current]:active::before, .menu-button__menu a[aria-current]:active:focus::before {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10.572" height="7.742" viewBox="0 0 10.572 7.742"><path d="M.681 3.812l3.148 2.931L9.864.708" fill="none" stroke="%231d7c79" stroke-linejoin="round" class="stroke" stroke-width="2"/></svg>');
}

.menu-button [aria-expanded=true] + .menu-button__menu {
  display: block;
}

.blocks {
  display: flex;
  flex-direction: column;
}

.blocks__block + .blocks__block {
  margin-top: 1.875rem;
}

.blocks__block--divider {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-family: "Noto Sans", sans-serif;
  font-size: 1.125rem;
  justify-content: center;
  margin: 1rem 0;
  position: relative;
  width: 100%;
}
.blocks__block--divider::before {
  border-bottom: solid 0.125rem;
  content: "";
  display: block;
  position: absolute;
  top: 0.75em;
  width: 100%;
}

.blocks__block--divider span {
  background-color: var(--parent-background-color, #fff);
  padding: 0 0.75rem;
  z-index: 1;
}

@media (min-width: 60rem) {
  .blocks {
    flex-direction: row;
  }

  .blocks__block + .blocks__block {
    margin-left: 1.875rem;
    margin-top: 0;
  }

  .blocks__block--divider {
    display: block;
    margin: 0 1.5rem;
    width: auto;
  }
  .blocks__block--divider::before {
    border-bottom: 0;
    border-left: solid 0.125rem;
    height: 100%;
    left: 50%;
    top: 0;
    width: 0.125rem;
  }

  .blocks__block--divider span {
    padding: 0.75rem 0;
    position: relative;
    top: calc(50% - 1em);
  }
}
.search-form {
  --border-width: 0.0625rem;
  display: flex;
  flex-direction: row;
}
.search-form label {
  width: 100%;
}
.search-form [type=search] {
  background-color: var(--blue-50);
  border-bottom-right-radius: 0;
  border-color: transparent;
  border-right-width: 0;
  border-top-right-radius: 0;
  font-family: "Noto Sans", sans-serif;
  height: 3.125rem;
  max-width: 100%;
}
.search-form [type=search]:hover {
  border-color: var(--blue-500);
  box-shadow: 0 0 0 calc(2 * var(--border-width)) var(--blue-500) inset;
}
.search-form [type=search]:focus {
  border-color: var(--blue-500);
  box-shadow: 0 0 0 calc(2 * var(--border-width)) var(--blue-500) inset, 0 0 0 calc(3 * var(--border-width)) var(--parent-background-color) inset, 0 0 0 calc(5 * var(--border-width)) var(--blue-500) inset;
}
.search-form .button--search {
  --focus-box-shadow:
  	0 0 0 calc(2 * var(--border-width)) var(--blue-500) inset,
  	0 0 0 calc(3 * var(--border-width)) var(--white) inset;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  height: 3.125rem;
  margin-left: 0;
  position: relative;
  width: 3.125rem;
}
.search-form .button--search:focus {
  border-color: var(--blue-500);
}

.search-form.search-form--inverse [type=search] {
  --parent-background-color: var(--off-white);
  background-color: var(--off-white);
  border-right-width: 0.0625rem;
}
.search-form.search-form--inverse [type=search]::placeholder {
  color: transparent;
}
.search-form.search-form--inverse [type=search]:hover {
  border-color: var(--blue-300);
  box-shadow: 0 0 0 calc(2 * var(--border-width)) var(--blue-300) inset;
}
.search-form.search-form--inverse [type=search]:focus {
  border-color: var(--white);
  box-shadow: 0 0 0 calc(2 * var(--border-width)) var(--white) inset, 0 0 0 calc(4 * var(--border-width)) var(--blue-500) inset;
}
.search-form.search-form--inverse .button--search {
  --color: var(--blue-500);
  --background-color: var(--blue-50);
  --border-color: transparent;
  --outline-color: var(--blue-600);
  --hover-color: var(--blue-600);
  --hover-background-color: var(--blue-150);
  --active-color: var(--blue-400);
  --active-background-color: var(--background-color);
  --focus-color: var(--blue-600);
  --focus-background-color: var(--background-color);
  --focus-border-color: var(--blue-50);
  --focus-box-shadow:
  	0 0 0 calc(2 * var(--border-width)) var(--focus-background-color) inset,
  	0 0 0 calc(4 * var(--border-width)) var(--outline-color) inset;
  border: solid var(--border-width) var(--border-color);
}
.search-form.search-form--inverse .button--search:focus {
  border-color: var(--focus-border-color);
}

.home__search .search-form.search-form--inverse [type=search],
.home__search .search-form.search-form--inverse .button--search {
  box-shadow: 0 calc(3 * var(--border-width)) calc(6 * var(--border-width)) rgba(0, 0, 0, 0.16);
}
.home__search .search-form.search-form--inverse [type=search]:hover {
  box-shadow: 0 0 0 calc(3 * var(--border-width)) var(--blue-300) inset;
}
.home__search .search-form.search-form--inverse [type=search]:focus {
  box-shadow: 0 0 0 calc(2 * var(--border-width)) var(--white) inset, 0 0 0 calc(4 * var(--border-width)) var(--blue-500) inset;
}
.home__search .search-form.search-form--inverse .button--search {
  --focus-box-shadow:
  	0 0 0 calc(2 * var(--border-width)) var(--focus-background-color) inset,
  	0 0 0 calc(4 * var(--border-width)) var(--outline-color) inset,
  	0 calc(3 * var(--border-width)) calc(var(--border-width) * 6) rgba(0, 0, 0, 0.16);
}
.home__search .search-form.search-form--inverse .button--search:focus {
  box-shadow: var(--focus-box-shadow);
}
@media (min-width: 37.5rem) {
  .home__search .search-form.search-form--inverse [type=search] {
    height: 3.75rem;
  }
  .home__search .search-form.search-form--inverse [type=search]::placeholder {
    color: var(--grey-500);
    opacity: 1;
  }
  .home__search .search-form.search-form--inverse .button--search {
    height: 3.75rem;
    width: 3.75rem;
  }
  .home__search .search-form.search-form--inverse .button--search::before {
    height: 3.5rem;
  }
}
@media (min-width: 80rem) {
  .home__search .search-form.search-form--inverse [type=search] {
    height: 4.375rem;
  }
  .home__search .search-form.search-form--inverse [type=submit] {
    height: 4.375rem;
    width: 4.375rem;
  }
  .home__search .search-form.search-form--inverse [type=submit]::before {
    height: 4.125rem;
  }
}

.saved-search {
  padding: 0.75rem 0 1.5rem;
}
.saved-search p + p {
  margin-top: 0.75rem;
}
.saved-search .badges {
  margin-top: 0;
}

.saved-search + .saved-search {
  border-top: solid 0.0625rem var(--grey-500);
}

.tabs [role=tablist] {
  background: var(--grey-400);
  border-bottom: solid 0.0625rem var(--grey-400);
  margin-bottom: 1.375rem;
  margin-left: -1.875rem;
  margin-right: -1.875rem;
  width: calc(100% + 3.75rem);
}

.tabs a.tab {
  --outline-color: var(--off-white);
  --background-color: var(--white);
  --hover-color: var(--off-white);
  --hover-background-color: var(--blue-500);
  --color: var(--blue-500);
  --active-color: var(--off-white);
  --active-background-color: var(--blue-500);
  --focus-background-color: var(--blue-500);
  --focus-color: var(--off-white);
  --focus-box-shadow: 0 0 0 0.125rem var(--outline-color) inset;
  align-items: center;
  border: 0;
  border-left: solid 0.1875rem transparent;
  border-right: solid 0.1875rem transparent;
  display: flex;
  flex-direction: row;
  font-family: "Noto Sans", sans-serif;
  font-size: 1rem;
  justify-content: space-between;
  line-height: 1.75;
  max-width: 100%;
  padding: 1rem 1rem 0.875rem;
  position: relative;
  text-align: left;
  text-decoration: none;
  width: 100%;
}
.tabs a.tab:hover, .tabs a.tab:active {
  border-left-color: transparent;
}
.tabs a.tab:focus {
  border: solid 0.1875rem transparent;
  padding: 0.8125rem 1rem 0.6875rem;
}
.tabs a.tab[aria-selected=true] {
  border-left-color: var(--red-400);
  font-weight: 700;
}
.tabs a.tab[aria-selected=true]:hover, .tabs a.tab[aria-selected=true]:focus {
  border-left-color: transparent;
}

.tabs.tabs--inverse [role=tablist] {
  background: var(--dark-mint-500);
  border-bottom-color: var(--dark-mint-500);
}

.tabs.tabs--inverse a.tab {
  --outline-color: var(--blue-600);
  --background-color: var(--blue-600);
  --color: var(--white);
  --hover-background-color: var(--off-white);
  --hover-color: var(--dark-mint-500);
  --active-background-color: var(--blue-50);
  --active-color: var(--dark-mint-500);
  --focus-background-color: var(--off-white);
  --focus-color: var(--dark-mint-500);
}

.tabs a.tab + a.tab {
  margin-top: 0.0625rem;
}

.tabs [role=tabpanel] {
  width: 100%;
}

@media (min-width: 60rem) {
  .tabs [role=tablist] {
    align-items: flex-end;
    background-color: var(--white);
    display: flex;
    flex-direction: row;
    height: 5rem;
    justify-content: flex-start;
    margin-bottom: 3.375rem;
    padding-left: 0.875rem;
    padding-right: 0.875rem;
    padding-top: 0;
    position: relative;
    width: 100vw;
  }

  .tabs a.tab {
    --background-color: transparent;
    --border-width: 0.125rem;
    --outline-color: var(--off-white);
    --hover-color: var(--off-white);
    --hover-background-color: var(--blue-500);
    --color: var(--blue-500);
    --active-color: var(--off-white);
    --active-background-color: var(--blue-500);
    --focus-background-color: var(--blue-500);
    --focus-color: var(--off-white);
    --focus-box-shadow:
    	0 0 0 var(--border-width) var(--focus-background-color) inset,
    	0 0 0 calc(var(--border-width) * 2) var(--outline-color) inset;
    --active-box-shadow: var(--focus-box-shadow);
    align-items: center;
    border: 0;
    display: flex;
    flex-direction: row;
    font-size: 1rem;
    font-weight: 400;
    height: 5rem;
    justify-content: center;
    margin: 0;
    padding: 0 1rem;
    width: auto;
  }
  .tabs a.tab::after {
    background-color: transparent;
    bottom: 0;
    content: "";
    height: 0.1875rem;
    left: 0;
    margin-left: 1rem;
    position: absolute;
    width: calc(100% - 2rem);
  }
  .tabs a.tab:focus, .tabs a.tab:active {
    border: 0;
    border-left: 0;
    box-shadow: var(--focus-box-shadow);
    padding: 0 1rem;
  }
  .tabs a.tab:focus::after, .tabs a.tab:active::after {
    background-color: transparent;
  }
  .tabs a.tab[aria-selected=true] {
    --color: var(--dark-mint-500);
    --background-color: transparent;
    --hover-color: var(--off-white);
    --hover-background-color: var(--blue-500);
    --focus-background-color: var(--blue-500);
    --focus-color: var(--off-white);
    font-weight: 400;
  }
  .tabs a.tab[aria-selected=true]::after {
    background-color: var(--red-400);
  }
  .tabs a.tab[aria-selected=true]:hover::after {
    background-color: transparent;
  }
  .tabs a.tab[aria-selected=true]:focus {
    border-left-color: transparent;
  }
  .tabs a.tab[aria-selected=true]:focus::after {
    background-color: transparent;
  }

  .tabs a.tab + a.tab {
    margin-top: 0;
  }

  .tabs.tabs--inverse [role=tablist] {
    background: var(--blue-600);
  }

  .tabs.tabs--inverse a.tab[aria-selected=true] {
    --color: var(--white);
    --background-color: transparent;
    --hover-background-color: var(--off-white);
    --hover-color: var(--dark-mint-500);
    --active-background-color: var(--blue-50);
    --active-color: var(--dark-mint-500);
    --focus-background-color: var(--off-white);
    --focus-color: var(--dark-mint-500);
  }
}
@media (min-width: 80rem) {
  .tabs [role=tablist] {
    margin-left: calc((100vw - 72.5rem) / 2 * -1);
    padding-left: calc(((100vw - 72.5rem) / 2) - 1rem);
    padding-right: calc(((100vw - 72.5rem) / 2) - 1rem);
  }
}
@media (min-width: 120rem) {
  .tabs [role=tablist] {
    margin-left: calc((100vw - 98.125rem) / 2 * -1);
    padding-left: calc(((100vw - 98.125rem) / 2) - 1rem);
    padding-right: calc(((100vw - 98.125rem) / 2) - 1rem);
  }
}
[role=banner] {
  --parent-background-color: var(--white);
  background-color: var(--white);
  box-shadow: 0 0.1875rem 0.375rem rgba(0, 0, 0, 0.16);
  width: 100%;
  z-index: 1;
}
[role=banner] .container {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}
[role=banner] .inner {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: relative;
  width: calc(100% - 5rem);
}
[role=banner] .link--brand {
  --color: var(--blue-500);
  --border-width: 0;
  --hover-color: var(--dark-mint-500);
  --active-color: var(--off-white);
  --active-background-color: var(--blue-500);
  --focus-color: var(--active-color);
  --focus-background-color: var(--active-background-color);
  --focus-box-shadow:
  	0 0 0 0.125rem var(--focus-background-color) inset,
  	0 0 0 0.25rem var(--focus-color) inset;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 5rem;
  justify-content: center;
  margin-left: 0;
  width: 5rem;
}
[role=banner] .link--brand::after {
  display: none;
}
[role=banner] .link--brand svg {
  display: block;
  height: 2rem;
  margin-bottom: 0;
  width: 2rem;
}
[role=banner] [aria-expanded=false] + .search-form {
  display: none;
}
[role=banner] .search-form label {
  width: auto;
}
@media (max-width: 37.4375rem) {
  [role=banner] [aria-expanded=true] + .search-form {
    --parent-background-color: var(--blue-500);
    background-color: var(--blue-500);
    display: flex;
    padding: 0.625rem;
    position: absolute;
    right: 0;
    top: 4.375rem;
    width: 100vw;
    z-index: 3;
  }
  [role=banner] [aria-expanded=true] + .search-form label {
    width: 100%;
  }
  [role=banner] [aria-expanded=true] + .search-form [type=search] {
    --parent-background-color: var(--off-white);
    background-color: var(--off-white);
    border-right-color: var(--grey-300);
    border-right-width: 0.0625rem;
  }
  [role=banner] [aria-expanded=true] + .search-form [type=search]::placeholder {
    color: transparent;
  }
  [role=banner] [aria-expanded=true] + .search-form [type=search]:hover {
    box-shadow: 0 0 0 0.1875rem var(--blue-300) inset;
  }
  [role=banner] [aria-expanded=true] + .search-form [type=search]:focus {
    box-shadow: 0 0 0 0.125rem var(--white) inset, 0 0 0 0.25rem var(--blue-500) inset;
  }
  [role=banner] [aria-expanded=true] + .search-form .button--search {
    --color: var(--blue-500);
    --background-color: var(--blue-50);
    --border-color: transparent;
    --border-width: 0.0625rem;
    --outline-color: var(--blue-600);
    --hover-color: var(--blue-600);
    --hover-background-color: var(--blue-150);
    --active-color: var(--blue-400);
    --active-background-color: var(--background-color);
    --focus-color: var(--blue-600);
    --focus-background-color: var(--background-color);
    --focus-border-color: var(--blue-50);
    --focus-box-shadow:
    	0 0 0 var(--border-width) var(--focus-background-color) inset,
    	0 0 0 calc(3 * var(--border-width)) var(--outline-color) inset;
    border: 0;
    border-bottom: solid 0.0625rem var(--border-color);
    border-right: solid 0.0625rem var(--border-color);
    border-top: solid 0.0625rem var(--border-color);
  }
  [role=banner] [aria-expanded=true] + .search-form .button--search:focus {
    border-color: var(--focus-border-color);
  }
}
.home [role=banner] {
  background-color: var(--blue-500);
  --parent-background-color: var(--blue-500);
}
.home [role=banner] .link--brand {
  --color: var(--off-white);
  --hover-color: var(--white);
  --visited-color: var(--color);
  --active-color: var(--blue-500);
  --active-background-color: var(--off-white);
}

.page-header {
  padding-bottom: 6.25rem;
  padding-top: 6.25rem;
  position: relative;
  width: calc(((100% - ((4 - 1) * 1.875rem)) * 1) + (3 * 1.875rem));
}
@supports (display: grid) {
  .page-header {
    grid-column: 1/5;
    width: 100%;
  }
}
.page-header p + h1 {
  margin-top: 2rem;
}

@media (min-width: 37.5rem) {
  [role=banner] .inner {
    justify-content: space-between;
  }
  [role=banner] .search-toggle {
    display: none;
  }
  [role=banner] [aria-expanded=false] + .search-form {
    display: flex;
  }

  .page-header {
    width: calc(((100% - ((8 - 1) * 1.875rem)) * 0.75) + (5 * 1.875rem));
    padding-bottom: 8.125rem;
    padding-top: 8.125rem;
  }
  @supports (display: grid) {
    .page-header {
      grid-column: 2/8;
      width: 100%;
    }
  }
}
@media (min-width: 60rem) {
  [role=banner] .container {
    margin: 0 auto;
    max-width: 72.5rem;
  }
  @supports (display: grid) {
    [role=banner] .container {
      display: grid;
      grid-template-columns: 1fr minmax(0, 72.5rem) 1fr;
      margin: unset;
      max-width: unset;
    }
  }
  [role=banner] .link--brand {
    height: 5rem;
    margin-left: 0rem;
    width: 5rem;
  }
}
@media (min-width: 80rem) {
  [role=banner] .inner {
    margin-left: 0;
    width: 100%;
  }

  .page-header {
    width: calc(((100% - ((12 - 1) * 1.875rem)) * 0.5) + (5 * 1.875rem));
  }
  @supports (display: grid) {
    .page-header {
      grid-column: 4/10;
      width: 100%;
    }
  }
  .page-header p + h1 {
    margin-top: 2.5rem;
  }
}
@media (min-width: 120rem) {
  [role=banner] .container {
    margin: 0 auto;
    max-width: 98.125rem;
  }
  @supports (display: grid) {
    [role=banner] .container {
      grid-template-columns: 1fr minmax(0, 98.125rem) 1fr;
      margin: unset;
      max-width: unset;
    }
  }

  .page-header {
    width: calc(((100% - ((16 - 1) * 1.875rem)) * 0.375) + (5 * 1.875rem));
  }
  @supports (display: grid) {
    .page-header {
      grid-column: 6/12;
      width: 100%;
    }
  }
}
.page {
  --parent-background-color: var(--off-white);
  background-color: var(--off-white);
}
.page main {
  padding-bottom: 6.25rem;
}

.page .page-header {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-bottom: 3.125rem;
  padding-bottom: 6.25rem;
  padding-top: 6.25rem;
}
.page .page-header > * {
  background-color: var(--blue-500);
  box-shadow: 0 0 0 1.875rem var(--blue-500);
  color: var(--white);
}
.page .page-header::before {
  background-image: url("../images/page.svg");
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  content: "";
  display: block;
  height: 100%;
  left: 50%;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
  width: 100vw;
  z-index: -1;
}
.page .page-header .link--breadcrumb {
  --background-color: transparent;
  --border-color: transparent;
  --color: var(--off-white);
  --outline-color: var(--blue-500);
  --hover-background-color: var(--background-color);
  --hover-border-color: var(--border-color);
  --hover-color: var(--white);
  --visited-color: var(--blue-50);
  --active-background-color: var(--blue-200);
  --active-border-color: var(--blue-200);
  --active-box-shadow:
  	0 0 0 calc(1 * var(--border-width)) var(--active-border-color),
  	0 0 0 calc(3 * var(--border-width)) var(--outline-color),
  	0 0 0 calc(5 * var(--border-width)) var(--active-border-color);
  --active-color: var(--blue-500);
  --focus-background-color: var(--background-color);
  --focus-border-color: var(--blue-200);
  --focus-box-shadow:
  	0 0 0 calc(3 * var(--border-width)) var(--outline-color),
  	0 0 0 calc(5 * var(--border-width)) var(--focus-border-color);
  --focus-color: var(--color);
}

@media (min-width: 37.5rem) {
  .page main {
    padding-bottom: 8.125rem;
    width: calc(((100% - ((8 - 1) * 1.875rem)) * 0.75) + (5 * 1.875rem));
  }
  @supports (display: grid) {
    .page main {
      grid-column: 2/8;
      width: 100%;
    }
  }
  .page .page-header {
    padding-bottom: 8.125rem;
    padding-top: 8.125rem;
  }
}
@media (min-width: 60rem) {
  .page:not(.home):not(.term-list) .page-header {
    margin-bottom: 4.0625rem;
  }
}
@media (min-width: 80rem) {
  .page main {
    width: calc(((100% - ((12 - 1) * 1.875rem)) * 0.5) + (5 * 1.875rem));
  }
  @supports (display: grid) {
    .page main {
      grid-column: 4/10;
      width: 100%;
    }
  }
}
@media (min-width: 120rem) {
  .page main {
    padding-bottom: 8.125rem;
    width: calc(((100% - ((16 - 1) * 1.875rem)) * 0.375) + (5 * 1.875rem));
  }
  @supports (display: grid) {
    .page main {
      grid-column: 6/12;
      width: 100%;
    }
  }
}
.page.home .banner-pattern {
  background: var(--blue-500);
  background-image: url("../images/home.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 31.25rem;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 100vw;
  z-index: -2;
}

.page.home .page-header {
  color: var(--white);
  display: block;
  margin-bottom: 0;
  margin-top: 8.75rem;
  padding-bottom: 4.375rem;
  padding-top: 1.5625rem;
  position: relative;
}
.page.home .page-header > * {
  background-color: transparent;
  box-shadow: none;
  color: inherit;
}
.page.home .page-header::before {
  background-color: rgba(28, 67, 66, 0.9);
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  content: "";
  display: block;
  height: 100%;
  margin-left: 50%;
  overflow: hidden;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
  width: 100vw;
  z-index: -1;
}
.page.home .page-header::before {
  background-image: unset;
  margin-left: 0;
}

.home__search {
  background-color: var(--blue-500);
  position: relative;
}
.home__search::before {
  background-color: var(--blue-500);
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  content: "";
  display: block;
  height: 100%;
  margin-left: 50%;
  overflow: hidden;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
  width: 100vw;
  z-index: -1;
}
.home__search .search-form {
  position: relative;
  top: -1.5625rem;
}

.home__browse {
  background-color: var(--blue-500);
  color: var(--white);
  padding-bottom: 2.5rem;
  padding-top: 0.5rem;
  position: relative;
}
.home__browse .link-list {
  margin-top: 1.25rem;
}
.home__browse p {
  margin-top: 2.25rem;
}
.home__browse::before {
  background-color: var(--blue-500);
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  content: "";
  display: block;
  height: 100%;
  margin-left: 50%;
  overflow: hidden;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
  width: 100vw;
  z-index: -1;
}

.home__feed {
  padding-top: 1.875rem;
}
.home__feed > header {
  align-items: baseline;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.home__feed > header a {
  margin-top: 0;
}
.home__feed > h3 {
  padding-top: 6rem;
  position: relative;
}
.home__feed > h3::before {
  background-color: var(--grey-200);
  content: "";
  display: block;
  height: 1rem;
  left: 0;
  margin: 2.5rem 0 2.5rem 50%;
  overflow: hidden;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
  width: 100vw;
}
.home__feed .meta-card-wrapper {
  margin-top: 0;
}
.home__feed .card-wrapper {
  margin-top: 0;
}

@media (min-width: 37.5rem) {
  .page.home .banner-pattern {
    height: 38rem;
  }

  .page.home .page-header {
    margin-top: 17.1875rem;
    padding-top: 3.75rem;
  }

  .page.home main {
    width: calc(((100% - ((8 - 1) * 1.875rem)) * 1) + (7 * 1.875rem));
  }
  @supports (display: grid) {
    .page.home main {
      grid-column: 1/9;
      width: 100%;
    }
  }

  .home__search .search-form {
    top: -1.875rem;
  }

  .home__browse {
    display: grid;
    grid-column-gap: 1.875rem;
    grid-template-columns: repeat(4, 1fr);
  }
  .home__browse h2 {
    width: calc(((100% - ((4 - 1) * 1.875rem)) * 1) + (3 * 1.875rem));
  }
  @supports (display: grid) {
    .home__browse h2 {
      grid-column: 1/5;
      width: 100%;
    }
  }
  .home__browse .link-list {
    width: calc(((100% - ((4 - 1) * 1.875rem)) * 1) + (3 * 1.875rem));
    display: grid;
    grid-column-gap: 1.875rem;
    grid-template-columns: repeat(4, 1fr);
  }
  @supports (display: grid) {
    .home__browse .link-list {
      grid-column: 1/5;
      width: 100%;
    }
  }
  .home__browse p {
    width: calc(((100% - ((4 - 1) * 1.875rem)) * 1) + (3 * 1.875rem));
  }
  @supports (display: grid) {
    .home__browse p {
      grid-column: 1/5;
      width: 100%;
    }
  }

  .home__feed > h3 {
    margin-top: 0;
    padding-top: 7rem;
  }
  .home__feed > h3::before {
    margin: 3rem 0 3rem 50%;
  }
  .home__feed .cards {
    display: grid;
  }
}
@media (min-width: 60rem) {
  .home__feed > h3 {
    padding-top: 8rem;
  }
  .home__feed > h3::before {
    margin: 3.5rem 0 3.5rem 50%;
  }
}
@media (min-width: 80rem) {
  .page.home main {
    width: calc(((100% - ((12 - 1) * 1.875rem)) * 1) + (11 * 1.875rem));
  }
  @supports (display: grid) {
    .page.home main {
      grid-column: 1/13;
      width: 100%;
    }
  }

  .home__browse {
    grid-template-columns: repeat(12, 1fr);
    padding-bottom: 4.25rem;
    padding-top: 2.25rem;
  }
  .home__browse h2 {
    width: calc(((100% - ((12 - 1) * 1.875rem)) * 1) + (11 * 1.875rem));
  }
  @supports (display: grid) {
    .home__browse h2 {
      grid-column: 1/13;
      width: 100%;
    }
  }
  .home__browse .link-list {
    width: calc(((100% - ((12 - 1) * 1.875rem)) * 1) + (11 * 1.875rem));
  }
  @supports (display: grid) {
    .home__browse .link-list {
      grid-column: 1/13;
      width: 100%;
    }
  }
  .home__browse p {
    width: calc(((100% - ((12 - 1) * 1.875rem)) * 1) + (11 * 1.875rem));
    margin-top: 1.5rem;
  }
  @supports (display: grid) {
    .home__browse p {
      grid-column: 1/13;
      width: 100%;
    }
  }

  .home__feed .meta-card-wrapper,
.home__feed > .card-wrapper {
    display: grid;
    grid-column-gap: 1.875rem;
    grid-template-columns: repeat(3, 1fr);
  }
  .home__feed .meta-card-wrapper > .card-wrapper {
    width: calc(((100% - ((3 - 1) * 1.875rem)) * 0.6666666667) + (1 * 1.875rem));
  }
  @supports (display: grid) {
    .home__feed .meta-card-wrapper > .card-wrapper {
      grid-column: 1/3;
      width: 100%;
    }
  }
  .home__feed .cards {
    grid-template-columns: repeat(2, 1fr);
  }
  .home__feed > .card-wrapper .cards {
    width: calc(((100% - ((3 - 1) * 1.875rem)) * 0.6666666667) + (1 * 1.875rem));
  }
  @supports (display: grid) {
    .home__feed > .card-wrapper .cards {
      grid-column: 1/3;
      width: 100%;
    }
  }
}
@media (min-width: 120rem) {
  .page.home main {
    width: calc(((100% - ((16 - 1) * 1.875rem)) * 0.75) + (11 * 1.875rem));
  }
  @supports (display: grid) {
    .page.home main {
      grid-column: 3/15;
      width: 100%;
    }
  }
}
footer {
  background: var(--dark-mint-500);
  color: var(--white);
  height: auto;
  margin-top: auto;
  position: relative;
  width: 100%;
}
footer .container {
  margin: 0 auto;
  max-width: 101.875rem;
}
footer .columns {
  padding: 2.5rem 1.875rem;
}
footer .column + .column {
  border-top: solid 0.1875rem var(--off-white);
  margin-top: 3.75rem;
  padding-top: 1.25rem;
}
footer .logo {
  display: inline-block;
  height: 3.15625rem;
  margin-left: -0.25rem;
  padding: 0 0.25rem;
}
footer .logo svg {
  display: block;
  height: 3.15625rem;
  margin-bottom: 0;
  width: 10.798125rem;
}
footer .logo .brand__label {
  display: block;
  font-size: 0.75rem;
}
footer .logo::after {
  display: none;
}
footer .nav {
  margin-top: 1rem;
}
footer .nav p {
  line-height: 1.75rem;
}
footer .nav p + p {
  margin-top: 0;
}

@media (min-width: 80rem) {
  footer .container {
    max-width: 72.5rem;
    padding-left: 0;
  }

  footer .columns {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 3.75rem 0;
  }
  footer .columns .column + .column {
    margin-top: 0;
  }
}
.archive,
.search-results {
  --parent-background-color: var(--off-white);
  background-color: var(--off-white);
}

.archive .page-header,
.search-results .page-header {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-bottom: 3.125rem;
  width: calc(((100% - ((4 - 1) * 1.875rem)) * 1) + (3 * 1.875rem));
}
.archive .page-header::before,
.search-results .page-header::before {
  background-image: url("../images/background-archive.svg");
}
.archive .page-header > *,
.search-results .page-header > * {
  background-color: var(--off-white);
  box-shadow: 0 0 0 1.875rem var(--off-white);
}
.archive .page-header::before,
.search-results .page-header::before {
  background-color: var(--white);
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  content: "";
  display: block;
  height: 100%;
  margin-left: 50%;
  overflow: hidden;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
  width: 100vw;
  z-index: -1;
}
@supports (display: grid) {
  .archive .page-header,
.search-results .page-header {
    grid-column: 1/5;
    width: 100%;
  }
}

.archive .current-filters,
.search-results .current-filters {
  width: calc(((100% - ((4 - 1) * 1.875rem)) * 1) + (3 * 1.875rem));
  margin-top: 1.875rem;
}
@supports (display: grid) {
  .archive .current-filters,
.search-results .current-filters {
    grid-column: 1/5;
    width: 100%;
  }
}
.archive .current-filters .h3,
.search-results .current-filters .h3 {
  margin-top: 1rem;
}
.archive .current-filters h2 + p,
.search-results .current-filters h2 + p {
  margin-top: 0.375rem;
}
.archive .current-filters .h4,
.search-results .current-filters .h4 {
  margin-top: 1.5rem;
}
.archive .current-filters .h3 + .h4,
.search-results .current-filters .h3 + .h4 {
  margin-top: 0.75rem;
}
.archive .current-filters * + .tags,
.search-results .current-filters * + .tags {
  margin-top: 0;
}

.archive main,
.search-results main {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

@media (min-width: 37.5rem) {
  @supports (display: grid) {
    .archive main,
.search-results main {
      column-gap: 1.875rem;
      display: grid;
      grid-template-columns: repeat(8, 1fr);
    }
  }

  .archive .page-header,
.search-results .page-header,
.archive .current-filters,
.search-results .current-filters {
    width: calc(((100% - ((8 - 1) * 1.875rem)) * 1) + (7 * 1.875rem));
  }
  @supports (display: grid) {
    .archive .page-header,
.search-results .page-header,
.archive .current-filters,
.search-results .current-filters {
      grid-column: 1/9;
      width: 100%;
    }
  }
}
@media (min-width: 60rem) {
  .archive .page-header,
.search-results .page-header {
    margin-bottom: 4.0625rem;
    padding: 9.8125rem 0 7.125rem;
  }
}
@media (min-width: 80rem) {
  @supports (display: grid) {
    .archive main,
.search-results main {
      grid-template-columns: repeat(12, 1fr);
      grid-template-rows: auto auto auto 1fr;
    }
  }

  .archive .page-header,
.search-results .page-header {
    padding: 9.25rem 0 9.625rem;
    width: calc(((100% - ((12 - 1) * 1.875rem)) * 1) + (11 * 1.875rem));
  }
  @supports (display: grid) {
    .archive .page-header,
.search-results .page-header {
      grid-column: 1/13;
      width: 100%;
    }
  }

  .archive .current-filters,
.search-results .current-filters {
    width: calc(((100% - ((12 - 1) * 1.875rem)) * 0.6666666667) + (7 * 1.875rem));
    margin-top: 0;
  }
  @supports (display: grid) {
    .archive .current-filters,
.search-results .current-filters {
      grid-column: 5/13;
      width: 100%;
    }
  }

  .archive:not([class*=taxonomy]) .filter-wrapper,
.search-results:not([class*=taxonomy]) .filter-wrapper {
    margin-top: 1.5rem;
  }
}
@media (min-width: 120rem) {
  @supports (display: grid) {
    .archive main,
.search-results main {
      grid-template-columns: repeat(16, 1fr);
    }
  }

  .archive .page-header,
.search-results .page-header {
    width: calc(((100% - ((16 - 1) * 1.875rem)) * 1) + (15 * 1.875rem));
  }
  @supports (display: grid) {
    .archive .page-header,
.search-results .page-header {
      grid-column: 1/17;
      width: 100%;
    }
  }

  .archive .current-filters,
.search-results .current-filters {
    width: calc(((100% - ((16 - 1) * 1.875rem)) * 0.75) + (11 * 1.875rem));
  }
  @supports (display: grid) {
    .archive .current-filters,
.search-results .current-filters {
      grid-column: 5/17;
      width: 100%;
    }
  }
}
.resource__header {
  padding-bottom: 3rem;
}

.resource__meta {
  font-size: 0.875rem;
}

.resource__meta svg {
  color: var(--grey-500);
}

.resource__meta.resource__subscription {
  color: var(--red-500);
}
.resource__meta.resource__subscription svg {
  color: inherit;
}

h1 + .resource__meta {
  margin-top: 1.5rem;
}

.resource__meta + .resource__meta {
  margin-top: 0.75rem;
}

.resource__meta-group {
  padding-left: 1.5em;
  text-indent: -1.5em;
}

.resource__meta-group a {
  display: inline;
}

.resource__abstract h2 + * {
  margin-top: 1.5rem;
}

.resource__tags .tags {
  margin-top: 0.5625rem;
}

.resource__cta a {
  max-width: 100%;
}

.resource__actions {
  margin-bottom: 4rem;
  margin-top: 3rem;
}
.resource__actions button,
.resource__actions .menu-button {
  text-align: left;
}

.resource__actions + .resource__meta {
  margin-bottom: 1.5rem;
}

.resource__related {
  padding-bottom: 5.75rem;
  padding-top: 4rem;
  position: relative;
}
.resource__related::before {
  background-color: var(--off-white);
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  content: "";
  display: block;
  height: 100%;
  margin-left: 50%;
  overflow: hidden;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
  width: 100vw;
  z-index: -1;
}

.single-lc_resource main {
  padding-bottom: 0;
}

@media (max-width: 37.4375rem) {
  .resource__cta {
    background: var(--white);
    bottom: 0;
    left: 0;
    padding: 0.75rem 0;
    position: sticky;
    width: 100%;
    z-index: 1;
  }
}
@media (min-width: 37.5rem) {
  .single-lc_resource main {
    width: calc(((100% - ((8 - 1) * 1.875rem)) * 0.75) + (5 * 1.875rem));
  }
  @supports (display: grid) {
    .single-lc_resource main {
      grid-column: 2/8;
      width: 100%;
    }
  }

  .resource__meta-group + .resource__meta-group {
    margin-left: 1.25rem;
  }

  .resource__cta {
    margin-top: 3.25rem;
  }

  .resource__actions {
    margin-top: 0.875rem;
  }
  .resource__actions button,
.resource__actions .menu-button {
    display: inline-block;
  }
}
@media (min-width: 60rem) {
  .resource__meta-group {
    display: inline-block;
  }
}
@media (min-width: 80rem) {
  .single-lc_resource main {
    width: calc(((100% - ((12 - 1) * 1.875rem)) * 0.6666666667) + (7 * 1.875rem));
  }
  @supports (display: grid) {
    .single-lc_resource main {
      grid-column: 3/11;
      width: 100%;
    }
  }

  .resource__abstract h2 + * {
    margin-top: 1.75rem;
  }

  .resource__actions {
    margin-bottom: 5rem;
    margin-top: 1.75rem;
  }

  .resource__related {
    padding-bottom: 8.125rem;
    padding-top: 5rem;
  }
}
@media (min-width: 120rem) {
  .single-lc_resource main {
    width: calc(((100% - ((16 - 1) * 1.875rem)) * 0.5) + (7 * 1.875rem));
  }
  @supports (display: grid) {
    .single-lc_resource main {
      grid-column: 5/13;
      width: 100%;
    }
  }
}
.page.page-template-page-favorites .page-header::before {
  background-image: url("../images/favorites.svg");
}

.page.page-template-page-favorites .cards .card__wrapper {
  display: grid;
  gap: 1rem;
  grid-template-columns: 100%;
  grid-template-rows: 1fr 3rem;
}

@media (min-width: 37.5rem) {
  .page.page-template-page-favorites .cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  @supports (display: grid) {
    .page.page-template-page-favorites .cards {
      display: grid;
      gap: 1.875rem;
      grid-template-columns: 100%;
    }
  }
  .page.page-template-page-favorites .cards .card__wrapper {
    margin-bottom: 1.875rem;
    width: 100%;
  }
  @supports (display: grid) {
    .page.page-template-page-favorites .cards .card__wrapper {
      margin-bottom: 0;
    }
  }
  .page.page-template-page-favorites .card:nth-child(even) {
    margin-left: 0;
  }
}
@media (min-width: 60rem) {
  @supports (display: grid) {
    .page.page-template-page-favorites .cards {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  .page.page-template-page-favorites .cards .card__wrapper {
    width: calc(50% - 1.875rem / 2);
  }
  @supports (display: grid) {
    .page.page-template-page-favorites .cards .card__wrapper {
      width: 100%;
    }
  }
  .page.page-template-page-favorites .card:nth-child(even) {
    margin-left: 1.875rem;
  }
  @supports (display: grid) {
    .page.page-template-page-favorites .card:nth-child(even) {
      margin-left: 0;
    }
  }
  .page.page-template-page-favorites .cards .card__wrapper + .card__wrapper {
    margin-top: 0;
  }
}
@media (min-width: 80rem) {
  .page.page-template-page-favorites main {
    width: calc(((100% - ((12 - 1) * 1.875rem)) * 0.6666666667) + (7 * 1.875rem));
  }
  @supports (display: grid) {
    .page.page-template-page-favorites main {
      grid-column: 3/11;
      width: 100%;
    }
  }
}
@media (min-width: 120rem) {
  .page.page-template-page-favorites main {
    width: calc(((100% - ((16 - 1) * 1.875rem)) * 0.5) + (7 * 1.875rem));
  }
  @supports (display: grid) {
    .page.page-template-page-favorites main {
      grid-column: 4/12;
      width: 100%;
    }
  }
}
.page.term-list .page-header > * {
  background-color: transparent;
  box-shadow: none;
  color: inherit;
}
.page.term-list .page-header .link--breadcrumb {
  --background-color: transparent;
  --border-color: transparent;
  --color: var(--blue-500);
  --outline-color: var(--white);
  --hover-background-color: var(--background-color);
  --hover-border-color: var(--border-color);
  --hover-color: var(--dark-mint-500);
  --visited-color: var(--blue-700);
  --active-background-color: var(--blue-500);
  --active-border-color: var(--blue-500);
  --active-box-shadow:
  	0 0 0 calc(1 * var(--border-width)) var(--active-border-color),
  	0 0 0 calc(3 * var(--border-width)) var(--outline-color),
  	0 0 0 calc(5 * var(--border-width)) var(--active-border-color);
  --active-color: var(--off-white);
  --focus-background-color: var(--background-color);
  --focus-border-color: var(--blue-500);
  --focus-box-shadow:
  	0 0 0 calc(3 * var(--border-width)) var(--outline-color),
  	0 0 0 calc(5 * var(--border-width)) var(--focus-border-color);
}
.page.term-list .page-header::before {
  background-image: unset;
}

.page.term-list .link-list {
  margin-top: 0;
}

.page.term-list .link-list h2 {
  margin-top: 4rem;
}

.page.term-list .link-list .link-list {
  margin-bottom: 4rem;
}

.page.term-list [role=document] {
  background-repeat: repeat-y;
  background-size: 100% auto;
}

.page.term-list.topics [role=document] {
  background-image: url("../images/background-topics.svg");
}

.page.term-list.goals [role=document] {
  background-image: url("../images/background-goals.svg");
}

.page.term-list.sectors [role=document] {
  background-image: url("../images/background-sectors.svg");
}

.page.term-list.coop-types [role=document] {
  background-image: url("../images/background-coop-types.svg");
}

.page.term-list.formats [role=document] {
  background-image: url("../images/background-formats.svg");
}

.content {
  display: block;
}

#main-map {
  bottom: 0;
  color: #203131;
  font-family: "Noto Sans", sans-serif;
  height: 580px;
  margin-top: 1.875rem;
  top: 0;
  width: 100%;
}

#main-map.organization {
  font-family: "Noto Serif", serif;
}

#geolocation-map {
  border: solid 1px var(--dark-mint-500);
  bottom: 0;
  color: var(--dark-mint-500);
  font-family: "Noto Sans", sans-serif;
  height: 29.75rem;
  margin-top: 1.875rem;
  top: 0;
  width: 100%;
}

#crosshairs {
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  z-index: 1;
}

li.no-bullet {
  list-style: none;
}

select.multiple {
  height: 10rem;
  overflow-y: auto;
}

.blocks {
  justify-content: center;
}

.align-center .button {
  display: inline-block;
  margin-left: 0;
  margin-right: 0;
}

.form-wizard .wrap.container {
  background: #f9f9f7;
}
.form-wizard .page-header {
  width: 100%;
  padding-bottom: 3.125rem;
  position: relative;
  z-index: 0;
}
.form-wizard .page-header::before {
  background-color: var(--white);
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  content: "";
  display: block;
  height: 100%;
  margin-left: 50%;
  overflow: hidden;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
  width: 100vw;
  z-index: -1;
}
@media (min-width: 60rem) {
  .form-wizard .page-header {
    padding-bottom: 4.0625rem;
  }
}
@media (min-width: 60rem) {
  .form-wizard .form__content,
.form-wizard .page-header {
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
}
.form-wizard .form__content {
  padding-top: 3.125rem;
}
@media (min-width: 60rem) {
  .form-wizard .form__content {
    padding-top: 4.0625rem;
  }
}
.form-wizard .form__content .role-1,
.form-wizard .form__content .role-2,
.form-wizard .form__content .role-3,
.form-wizard .form__content .role-4,
.form-wizard .form__content .role-5,
.form-wizard .form__content .role-6,
.form-wizard .form__content .role-7,
.form-wizard .form__content .role-8 {
  display: none;
}
.form-wizard .form__content.role-1 .role-1, .form-wizard .form__content.role-2 .role-2, .form-wizard .form__content.role-3 .role-3, .form-wizard .form__content.role-4 .role-4, .form-wizard .form__content.role-5 .role-5, .form-wizard .form__content.role-6 .role-6, .form-wizard .form__content.role-7 .role-7, .form-wizard .form__content.role-8 .role-8 {
  display: block;
}
.form-wizard .form__content .type-1,
.form-wizard .form__content .type-6,
.form-wizard .form__content .type-7,
.form-wizard .form__content .type-8 {
  display: none;
}
.form-wizard .form__content.type-1 .type-1, .form-wizard .form__content.type-6 .type-6, .form-wizard .form__content.type-7 .type-7, .form-wizard .form__content.type-8 .type-8 {
  display: block;
}
.form-wizard .form__content .errorlist {
  color: var(--input-group--error-color);
  font-family: "Noto Sans", sans-serif;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.4285714286;
  list-style: none;
  margin-top: 0.75rem;
}
.form-wizard .form__content .errorlist li::before {
  background-color: var(--input-group--error-color);
  content: "";
  display: inline-block;
  height: 1.25em;
  margin-bottom: -0.25em;
  margin-right: 0.25em;
  mask-image: url(../images/error.svg);
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: 100%;
  width: 1.25em;
}
.form-wizard .errorlist + input,
.form-wizard .errorlist + textarea,
.form-wizard .errorlist + select {
  --text-border-color-default: var(--text-border-color-error);
  --text-border-color-hover: var(--text-border-color-error-hover);
  --text-border-color-focus: var(--text-border-color-error-focus);
  --text-default-box-shadow: var(--text-error-box-shadow);
  --text-focus-box-shadow: var(--text-error-focus-box-shadow);
  --text-hover-box-shadow: var(--text-error-hover-box-shadow);
  color: var(--text-value-color-error);
  margin-top: 0.375rem;
}
.form-wizard .errorlist + select {
  background-image: url(../images/chevron-down--error.svg);
}
.form-wizard label.required::after {
  content: " (required)";
}
.form-wizard input + label,
.form-wizard textarea + label,
.form-wizard select + label,
.form-wizard div + label,
.form-wizard .helptext + label,
.form-wizard .input-group + label,
.form-wizard ul.checkbox + label,
.form-wizard ul.radio + label {
  margin-top: 2.8125rem;
}
.form-wizard input + br,
.form-wizard textarea + br,
.form-wizard select + br,
.form-wizard ul.checkbox + br,
.form-wizard ul.radio + br {
  display: none;
}

select[multiple] {
  width: 100%;
}

.button--auto {
  width: auto;
}

#scope-and-impact [for=id_scope_and_impact-geo_scope_city],
#scope-and-impact #id_scope_and_impact-geo_scope_city,
#scope-and-impact [for=id_scope_and_impact-geo_scope_region],
#scope-and-impact #id_scope_and_impact-geo_scope_region,
#scope-and-impact [for=id_scope_and_impact-geo_scope_country],
#scope-and-impact #id_scope_and_impact-geo_scope_country {
  display: none;
}

#scope-and-impact.show-city [for=id_scope_and_impact-geo_scope_city],
#scope-and-impact.show-city #id_scope_and_impact-geo_scope_city {
  display: block;
}

#scope-and-impact.show-region [for=id_scope_and_impact-geo_scope_region],
#scope-and-impact.show-region #id_scope_and_impact-geo_scope_region {
  display: block;
}

#scope-and-impact.show-country [for=id_scope_and_impact-geo_scope_country],
#scope-and-impact.show-country #id_scope_and_impact-geo_scope_country {
  display: block;
}

.my-profiles .cards .card__wrapper,
.results .cards .card__wrapper {
  display: grid;
  gap: 1.25rem;
  grid-template-columns: 100%;
  grid-template-rows: 1fr 3rem;
}

.card_request {
  --parent-background-color: var(--white);
  background-color: var(--white);
  border-top: 0.1875rem solid var(--blue-500);
  box-shadow: 0 0 0.3125rem rgba(0, 0, 0, 0.16);
  height: 100%;
  padding: 1.375rem;
  position: relative;
  transition: box-shadow 0.1s;
  width: 100%;
}

@media (min-width: 37.5rem) {
  .my-profiles .cards,
.results .cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  @supports (display: grid) {
    .my-profiles .cards,
.results .cards {
      display: grid;
      gap: 1.875rem;
      grid-template-columns: 100%;
    }
  }
  .my-profiles .cards .card__wrapper,
.results .cards .card__wrapper {
    margin-bottom: 1.875rem;
    width: 100%;
  }

  @supports (display: grid) {
    .my-profiles .cards .card__wrapper,
.results .cards .card__wrapper {
      margin-bottom: 0;
    }
  }
  .my-profiles .card:nth-child(2n) {
    margin-left: 0;
  }
}
@media (min-width: 60rem) {
  .menu li:last-child.menu__submenu-wrapper .menu__submenu {
    min-width: unset;
    right: 0;
    width: auto;
  }

  @supports (display: grid) {
    .my-profiles .cards,
.results .cards {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  .my-profiles .cards .card__wrapper,
.results .cards .card__wrapper {
    width: calc(50% - 0.9375rem);
  }

  @supports (display: grid) {
    .my-profiles .cards .card__wrapper,
.results .cards .card__wrapper {
      width: 100%;
    }
  }
  .my-profiles .card:nth-child(2n),
.results .card:nth-child(2n) {
    margin-left: 1.875rem;
  }

  @supports (display: grid) {
    .my-profiles .card:nth-child(2n),
.results .card:nth-child(2n) {
      margin-left: 0;
    }
  }
  .my-profiles .cards .card__wrapper + .card__wrapper,
.results .cards .card__wrapper + .card__wrapper {
    margin-top: 0;
  }
}
@media (min-width: 80rem) {
  .my-profiles main,
.results main {
    width: calc(66.66667% - 0.625rem);
  }

  @supports (display: grid) {
    .my-profiles main,
.results main {
      grid-column: 3/11;
      width: 100%;
    }
  }
}
@media (min-width: 120rem) {
  .my-profiles main,
.results main {
    width: calc(50% - 0.9375rem);
  }

  @supports (display: grid) {
    .my-profiles main,
.results main {
      grid-column: 4/12;
      width: 100%;
    }
  }
}
.card--organization .card__type,
.card--individual .card__roles {
  color: var(--dark-mint-500);
  font-weight: 700;
}

* + .card__role {
  margin-top: 0.5625rem;
}

.box {
  border: dashed 0.0625rem var(--grey-500);
  padding: var(--gutter);
}

.account {
  background: #f9f9f7;
}
.account .page-header {
  padding-bottom: 3.125rem;
  position: relative;
  z-index: 0;
}
@media (min-width: 60rem) {
  .account .page-header {
    padding-bottom: 4.0625rem;
  }
}
@media (min-width: 60rem) {
  .account .stack,
.account .page-header {
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
}

.input-group + .buttons {
  margin-top: 1.5rem;
}

.buttons .button + .button {
  margin-top: 1.5rem;
}

.button.button-default {
  background-color: var(--grey-200);
  color: var(--black);
}

.radio label.primary {
  font-weight: 700;
}
.radio label.primary .badge {
  font-weight: 400;
}

.card--individual {
  border-top-color: #45d385;
}
.card--individual:focus-within {
  border-color: #45d385;
  box-shadow: 0 0 1.5625rem rgba(69, 211, 133, 0.25);
}
.card--individual .card__title a:focus {
  background: #45d385;
  color: #fff;
}
.card--individual .card__title a:focus::after {
  background-color: #fff;
}
.card--individual:focus-within .card__title a:focus {
  background: unset;
  color: var(--dark-mint-500);
}
.card--individual .card__format {
  background: unset;
  color: var(--dark-mint-500);
}

.card--tool {
  border-top-color: #face00;
}
.card--tool:focus-within {
  border-color: #face00;
  box-shadow: 0 0 1.5625rem rgba(250, 206, 0, 0.25);
}
.card--tool .card__title a:focus {
  background: #face00;
  color: #fff;
}
.card--tool .card__title a:focus::after {
  background-color: #fff;
}
.card--tool:focus-within .card__title a:focus {
  background: unset;
  color: var(--dark-mint-500);
}
.card--tool .card__format {
  background: unset;
  color: var(--dark-mint-500);
}

.card__niche {
  font-weight: 700;
}

.stack > * + .h4 {
  margin-top: 3rem;
}

.profile__meta {
  font-size: 0.875rem;
  line-height: 1.4285714286;
}

.profile__meta--empty,
.--empty {
  font-style: italic;
}

h1 + .profile__meta {
  margin-top: 1.5rem;
}

.profile__meta + .profile__meta {
  margin-top: 0.75rem;
}

.profile__meta svg {
  color: var(--grey-500);
}

.panel__content * + h2::before {
  background-color: var(--grey-400);
  content: "";
  display: block;
  height: 0.0625rem;
  margin-bottom: 3rem;
  margin-top: -1rem;
  width: 100%;
}

.panel__content h3 + h2::before {
  margin-top: 3rem;
}

.panel__content .cards + h2::before {
  margin-top: 0;
}

.tabs [role=tablist] {
  border-top: solid 0.0625rem var(--grey-400);
  margin-bottom: 0;
}
.tabs [role=tablist]::before {
  background-color: var(--grey-200);
  border-top: solid 0.0625rem var(--grey-400);
  content: "";
  display: block;
  height: 1rem;
  left: 0;
  position: absolute;
  top: -1.125rem;
  width: inherit;
}

.tabs [role=tabpanel] {
  --parent-background-color: var(--off-white);
  background-color: var(--off-white);
  padding-bottom: 6.25rem;
  padding-top: 3.375rem;
  position: relative;
}
.tabs [role=tabpanel]::before {
  background-color: #f9f9f7;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  content: "";
  display: block;
  height: 100%;
  margin-left: 50%;
  overflow: hidden;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
  width: 100vw;
  z-index: -1;
}
@media (min-width: 60rem) {
  .tabs [role=tabpanel] {
    padding-bottom: 8.125rem;
  }
}

.profile .page-header {
  background-color: var(--white);
}
.profile .page-header::before {
  background-color: #fff;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  content: "";
  display: block;
  height: 100%;
  margin-left: 50%;
  overflow: hidden;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
  width: 100vw;
  z-index: -1;
}

.profile main {
  padding-bottom: 0;
}

.panel__sidebar {
  margin-bottom: 1.875rem;
}

@media (min-width: 60rem) {
  .profile [role=tabpanel] > .cards {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 80rem) {
  .profile [role=tabpanel]#overview {
    grid-gap: 1.875rem;
    grid-template-columns: repeat(12, 1fr);
  }
  .profile [role=tabpanel]#overview:not([hidden]) {
    display: grid;
  }
  .profile [role=tabpanel]#overview .edit {
    grid-column: 1/13;
  }
  .profile [role=tabpanel]#overview .panel__sidebar {
    grid-column: 1/4;
  }
  .profile [role=tabpanel]#overview .panel__content {
    grid-column: 4/13;
  }
}
@media (min-width: 120rem) {
  .profile [role=tabpanel]#overview {
    grid-template-columns: repeat(16, 1fr);
  }
  .profile [role=tabpanel]#overview .edit {
    grid-column: 1/17;
  }
  .profile [role=tabpanel]#overview .panel__sidebar {
    grid-column: 1/4;
  }
  .profile [role=tabpanel]#overview .panel__content {
    grid-column: 5/17;
  }
}
.profile__meta a,
.no-bullet a {
  line-height: inherit;
}

.profile__roles {
  font-weight: 700;
}
.profile__roles svg {
  color: inherit;
}

.h4 + .profile__meta {
  margin-top: 1rem;
}

.h4 + .tags {
  margin-top: 0;
}

.columns .column + .column {
  margin-top: 3.5rem;
}
@media (min-width: 80rem) {
  .columns {
    column-gap: 1.875rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
  .columns .column + .column {
    margin-top: 0;
  }
}

.niches > li {
  padding: 0.5rem 0 0.375rem;
  position: relative;
}

.niches button {
  position: absolute;
  right: 0;
  top: 0;
}

[for=id_detailed_info-license],
#id_detailed_info-license,
.form-wizard--tool [for=id_detailed_info-sectors],
.form-wizard--tool #id_detailed_info-sectors {
  display: none;
}

.form__content > * + div {
  margin-top: 2.8125rem;
}

.page.page--impact .page-header::before {
  background-color: var(--blue-500);
  background-image: unset;
}

.summary > div {
  background-color: #fff;
  padding: var(--gutter);
}
.summary > div + div {
  margin-top: 1.875rem;
}
.summary span {
  display: block;
}
.summary span.dot {
  border-radius: 50%;
  display: inline-block;
  height: 1em;
  margin-right: 0.5rem;
  width: 1em;
}
.summary .scope figure {
  margin: 1.875rem 0 0;
  padding: 0;
}
.summary .scope .h4 {
  text-align: center;
}
.summary .scope figcaption {
  margin-top: 1.875rem;
}

.list-unstyled {
  list-style: none;
}

.list-inline li {
  display: inline-flex;
}

@media (min-width: 37.5rem) {
  .summary .scope figure {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .summary .scope .chart {
    width: calc(50% - var(--gutter) / 2);
  }
  .summary .scope figcaption {
    margin-top: 0;
    width: calc(50% - var(--gutter) / 2);
  }
}
@media (min-width: 60rem) {
  .summary {
    display: grid;
    grid-gap: var(--gutter);
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
  .summary > div + div {
    margin-top: 0;
  }
  .summary .scope {
    grid-column: 1/3;
    grid-row: 2/4;
  }
  .summary .scope .h4 {
    margin-top: 0;
    text-align: center;
  }
  .summary .scope figure {
    align-items: center;
  }
  .summary .scope .chart {
    width: 50%;
  }
  .summary .scope figcaption {
    font-size: 0.875rem;
    width: calc(50% - var(--gutter));
  }
}
@media (min-width: 80rem) {
  .page.page--impact main {
    width: calc(((100% - ((12 - 1) * 1.875rem)) * 0.6666666667) + (7 * 1.875rem));
  }
  @supports (display: grid) {
    .page.page--impact main {
      grid-column: 5/13;
      width: 100%;
    }
  }
  .page.page--impact .form {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .page.page--impact .input-group {
    width: calc(50% - (var(--gutter) / 2));
  }
  .page.page--impact .input-group + .input-group {
    margin-top: 0;
  }
}
.page.home [role=banner] {
  --parent-background-color: var(--blue-700);
  background-color: var(--blue-700);
  box-shadow: none;
}
.page.home .menu {
  --parent-background-color: var(--blue-700);
  background-color: var(--blue-700);
}
.page.home .banner-pattern {
  display: none;
}
.page.home .page-header {
  --parent-background-color: var(--dark-mint-500);
  background-color: var(--dark-mint-500);
  margin-top: 0;
}
.page.home .page-header::before {
  background-color: var(--dark-mint-500);
}
.page.home .home__search {
  background-color: var(--dark-mint-500);
}
.page.home .home__search::before {
  background-color: var(--dark-mint-500);
}
.page.home .card {
  cursor: pointer;
}

.page.page--directory .page-header::before {
  background-color: var(--blue-500);
  background-image: unset;
}

.mapboxgl-popup-close-button {
  font-size: 1.5rem;
}

.mapboxgl-popup-content .card__title {
  padding-right: 1.5rem;
}
.mapboxgl-popup-content .card__meta {
  --parent-background-color: var(--white);
  font-family: "Noto Serif", sans-serif;
}
.mapboxgl-popup-content .card__aside .card__meta {
  color: var(--grey-500);
}
.mapboxgl-popup-content .card__type {
  font-weight: 700;
}
.mapboxgl-popup-content .card__profile-link {
  font-weight: 400;
  padding-left: 0;
  text-indent: 0;
}
.mapboxgl-popup-content .card___number {
  font-size: 2rem;
}

@media (min-width: 120rem) {
  .page-header {
    grid-column: 4/10;
    width: 100%;
  }
}